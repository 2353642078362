import { Box, Typography, Chip, Tooltip, useTheme, Grid } from '@material-ui/core'
import { components, DataFlattener, constants, useTranslation, useServices,useWhiteLabelServiceSettings } from 'cng-web-lib'
import AceHighwayShipmentApiUrls from 'src/apiUrls/AceHighwayShipmentApiUrls'
import NaCodeMaintenanceApiURLs from 'src/apiUrls/NaCodeMaintenanceApiUrls'
import React, { useEffect, useRef, useState } from 'react'
import AceHighwayShipmentKeys from 'src/constants/locale/key/AceHighwayShipment'
import Namespace from 'src/constants/locale/Namespace'
import moment from 'moment-timezone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TinyChip from '../../../components/aciacehighway/TinyChip'
import ConfirmDialog from '../../common/ConfirmDialog'
import Table from '../../../components/aciacehighway/Table'
import pathMap from 'src/paths/pathMap'
import { getStatusMetadata } from 'src/common/NACommon'
import CngBackdrop from "src/components/cngcomponents/CngBackDrop";
import { FileForUserGetPartyId, FileForUserGetUserDetails,GetLoggedInUserPartyName } from 'src/common/FileForUserCommon'
import FormProperties from '../shipment/FormProperties'
import axios from 'axios'
import { format } from 'date-fns'
import sweetalert from 'sweetalert'
import ShipmentXMLUploadDialog from './upload/ShipmentXMLUploadDialog'
import ResponseLog from '../manifest/view/ResponseLog'
import { fetchUser } from "src/views/userprofile/UserProfileService.js";
import ChargeTypeDialog from '../../common/ChargeTypeDialog'

const {
  button: { CngButton },
  table: { useFetchCodeMaintenanceLookup },
  CngGridItem,
  CngDialog
} = components

const {
  filter: { BETWEEN, EQUAL, IN, LIKE, NOT_EQUAL }
} = constants

function TablePage(props) {
  const { history, location, showNotification } = props

  const [lookups, setLookups] = useState(null)
  const [confirmDialog, setConfirmDialog] = useState({ open: false, shipment: null })
  const [massDeleteConfirmDialog, setMassDeleteConfirmDialog] = useState({ open: false, idList: null })

  const [loading, setLoading] = useState(false)
  const [uploadXMLShipmentDialog, setUploadXMLShipmentDialog] = useState({ open: false, tableRef: null })

  const [topBarAction, setTopBarAction] = useState([])
  const [superParty,setSuperParty] = useState(false);
  const theme = useTheme()

  const tableRef = useRef(null)
  const { createRecord, deleteRecord, fetchRecords, securedSendRequest } = useServices()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.ACE_HIGHWAY_SHIPMENT
  ])
  const whiteLabelServiceSettings = useWhiteLabelServiceSettings("ace-highway")
  const translatedTextsObject = makeTranslatedTextsObject()
  
  const [chargeTypes, setChargeTypes] = useState([])
  const [submitDialog, setSubmitDialog] = useState({ open: false, items: [] })
  const [user, setUser] = useState([]);
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    fetchUser(setUser, securedSendRequest, fileForUserDetails);
  }, []);

  const customTopBarAction = [
    {
      buttonProps: {
        color: 'primary',
        size: 'medium',
        startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
        onClick: () => setUploadXMLShipmentDialog({ open: true, tableRef: tableRef })
      },
      label: translatedTextsObject.uploadXMLButton
    },
    {
      buttonProps: {
        color: 'primary',
        size: 'medium',
        startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
        onClick: () => history.push(pathMap.ACE_HIGHWAY_SHIPMENT_ADD_VIEW)
      },
      label: 'Create shipment'
    },
    {
      buttonProps: {
        color: 'primary',
        size: 'medium',
        startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
        onClick: () => {
          var data = {...FormProperties.formikProps.initialValues}
          data.masterIndicator = true
          history.push(pathMap.ACE_HIGHWAY_SHIPMENT_ADD_VIEW, data)
        }
      },
      label: 'Create original split shipment'
    }
  ]

  const defaultTopBarAction = [
    {
      buttonProps: {
        color: 'primary',
        size: 'medium',
        startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
        onClick: () => history.push(pathMap.ACE_HIGHWAY_SHIPMENT_ADD_VIEW)
      },
      label: 'Create shipment'
    },
    {
      buttonProps: {
        color: 'primary',
        size: 'medium',
        startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
        onClick: () => {
          var data = {...FormProperties.formikProps.initialValues}
          data.masterIndicator = true
          history.push(pathMap.ACE_HIGHWAY_SHIPMENT_ADD_VIEW, data)
        }
      },
      label: 'Create original split shipment'
    }
  ]
  const [quickGlanceResponseLogDialog, setQuickGlanceResponseLogDialog] = useState({
    open: false,
    shipment: null
  })
  useEffect(() => {
    Promise.all([
      // Shipment type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACE_HW_SHIPMENT_TYPE' }],
        undefined,
        'code'
      ),
      // Status
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACE_HW_STATUS' }],
        undefined,
        'code'
      ),
      // Charge type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_CHARGE_TYPE' }],
        undefined,
        'code'
      ),
      // Super party
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_SUPER_PARTY' }],
        undefined, 'code'
      ),
      // Point of Loading
      fetchRecords.execute(
        NaCodeMaintenanceApiURLs.GET,
        {
          filters: [
            { field: 'set_indicator', operator: EQUAL, value: 'ACEHWY_POL' }
          ],
          customData: { codeMType: 'DocPortCode' }
        },
        (res) => {
          const result = {}

          res.content.forEach((item) => {
            result[item.code] = item.descriptionEn
          })

          return result
        }
      )
    ]).then(([shipmentType, status, billingChargeTypes, billingSuperParty, pointOfLoading]) => {
      setLookups({
        shipmentType,
        status,
        pointOfLoading
      })

      compileChargeTypes(billingChargeTypes)

      Object.keys(billingSuperParty).forEach(e => {
        if (e == GetLoggedInUserPartyName()) {
          setSuperParty(true);
        }
      })
  
    })

    if (whiteLabelServiceSettings.length > 0) {
      let shipmentXmlUpload = whiteLabelServiceSettings[0].custom.UI_SHIPMENT_TABLE_XML_UPLOAD
      if (shipmentXmlUpload == "true") {
        setTopBarAction(customTopBarAction)
      } else {
        setTopBarAction(defaultTopBarAction)
      }
    } else {
        setTopBarAction(defaultTopBarAction)
    }
  }, [])

  function compileChargeTypes(billingChargeTypes) {
    var jsonArray = [];
    if (billingChargeTypes) {
      Object.entries(billingChargeTypes).forEach((e) => {
        var jsonObject = { code: e[0], desc: e[1] }
        jsonArray.push(jsonObject)
      })
    }
    setChargeTypes(jsonArray)
  }

  useEffect(() => {
    if(superParty){
      setTopBarAction(customTopBarAction)
    }
  },[superParty]);

  function preCancel(data) {
    if (superParty || (null != user.corpid && undefined != user.corpid && user.corpid.length > 0 && user.corpid[0].serviceBureauFlag)) {
      setSubmitDialog({ open: true, items: data })
    } else {
      data.chargeAs = 'REGULAR'
      handleCancelMasterShipment(data)
    }
  }

  function handleCancelMasterShipment(data) {
    setLoading(true)
    const values = { ...data }

    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      values.fileForUserId = fileForUserDetails.fileForUserId;
      values.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      values.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }

    values.messageFunction = '22'
    values.messageIndicator = 'SINGLE'

    const instance = axios.create({
      withCredentials: false,
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userToken'))
      }
    })

    instance
      .post(AceHighwayShipmentApiUrls.CANCEL, DataFlattener.unflatten(FormProperties.toServerDataFormat(values)))
      .then(
        (result) => {
          var returnData = result.data
          var errorMessages = returnData.errorMessages
          if (
            result.status == 200 &&
            returnData != null &&
            errorMessages == null
          ) {
            showNotification('success', 'Shipment cancelled.')
            tableRef.current.performRefresh()
            setLoading(false)
          } else {
            showNotification('error', errorMessages)
          }
        },
        (error) => {
          console.log(error)
        }
      )
  }

  function makeTranslatedTextsObject() {
    let headerId = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.HEADER_ID
    )
    let shipmentType = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.SHIPMENT_TYPE
    )
    let entryType = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.ENTRY_TYPE
    )
    let scn = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.SCN
    )
    let entryNo = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.ENTRY_NO
    )
    let status = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.STATUS
    )
    let shipperName = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.SHIPPER_NAME
    )
    let consigneeName = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.CONSIGNEE_NAME
    )
    let createdDate = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.CREATED_DATE
    )
    let responseDate = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.RESPONSE_DATE
    )
    let editSentRecords = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.EDIT_SENT_RECORDS
    )
    let editButton = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.EDIT_BUTTON
    )
    let cloneButton = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.CLONE_BUTTON
    )
    let deleteButton = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.DELETE_BUTTON
    )
    let cancelButton = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.CANCEL_BUTTON
    )
    let deleteMultipleRecords = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.DELETE_MULTIPLE_RECORDS
    )
    let acquittalNo = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.ACQUITTAL_NO
    )
    let pointOfLoading = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.POINT_OF_LOADING
    )
    let uploadXMLButton = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.UPLOAD_XML_BUTTON
    )
    let masterShipmentId = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.MASTER_SHIPMENT_ID
    )
    let tripNo = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.TRIP_NO
    )
    let arrivalDate = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.ARRIVAL_DATE
    )

    return {
      headerId,
      shipmentType,
      entryType,
      scn,
      entryNo,
      status,
      shipperName,
      consigneeName,
      createdDate,
      responseDate,
      editSentRecords,
      editButton,
      cloneButton,
      deleteButton,
      cancelButton,
      deleteMultipleRecords,
      acquittalNo,
      pointOfLoading,
      uploadXMLButton,
      masterShipmentId,
      tripNo,
      arrivalDate
    }
  }

  if(loading){
    return(
      <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
        <CngBackdrop loading={loading} />
      </CngGridItem>
    )
  }
  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  const columns = [
    {
      field: 'masterShipmentId',
      sortKey: 'masterShipmentId',
      title: translatedTextsObject.masterShipmentId,
    },
    {
      field: 'shipmentType',
      sortKey: 'shipmentType',
      title: translatedTextsObject.shipmentType,
    },
    {
      field: 'scn',
      sortKey: 'scn',
      title: translatedTextsObject.scn,
      render: (data) => (
        <Box>
          <Typography component='div' variant='inherit' style={{ display: 'flex' }}>
            {data.masterIndicator && (<TinyChip label={'M'} variant='outlined' style={{ margin: 0, marginRight: 5 }} />)} {data.scn}
          </Typography>
        </Box>
      )
    },
    {
      field: 'pointOfLoading',
      sortKey: 'pointOfLoading',
      title: translatedTextsObject.pointOfLoading,
      render: (data) =>
        <Typography component='div' variant='inherit' style={{ marginBottom: 4 }}>
          {getLookupValue('pointOfLoading', data.pointOfLoading)}
          <TinyChip label={data.pointOfLoading} variant='outlined' />
        </Typography>
    },
    {
      field: 'arrivalDate',
      sortKey: 'arrivalDate',
      title: translatedTextsObject.arrivalDate,
      render: (data) => data.arrivalDate && moment(data.arrivalDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    {
      field: 'shipperName',
      sortKey: 'shipperName',
      title: translatedTextsObject.shipperName
    },
    {
      field: 'consigneeName',
      sortKey: 'consigneeName',
      title: translatedTextsObject.consigneeName
    },
    {
      field: 'createdDate',
      sortKey: 'createdDate',
      title: translatedTextsObject.createdDate,
      render: (data) =>
        data.createdDate && moment(data.createdDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    {
      field: 'updatedDate',
      sortKey: 'updatedDate',
      title: 'Last modified date',
      render: (data) =>
        data.updatedDate && moment(data.updatedDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    {
      field: 'submittedDate',
      sortKey: 'submittedDate',
      title: 'Submission date',
      render: (data) =>
        data.submittedDate && moment(data.submittedDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    {
      field: 'responseDate',
      sortKey: 'responseDate',
      title: translatedTextsObject.responseDate,
      render: (data) =>
        data.responseDate &&
        moment(data.responseDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    {
      field: 'brokerEntryNo',
      sortKey: 'brokerEntryNo',
      title: translatedTextsObject.entryNo
    },
    {
      field: 'status',
      sortKey: 'status',
      title: translatedTextsObject.status,
      render: (data) => {
        const status = getStatusMetadata(data.status)

        if (data.status == '1000' && data.shipmentType == 'REGULAR' && !data.brokerEntryNo) {
          status.color = "#ffc400"
          status.contrastColor = "#000000"
        }

        return (
          <Tooltip key='more' title='View response(s)' placement='top'>
            <Chip
              label={status.label}
              size='small'
              style={{
                backgroundColor: status.color,
                color: status.contrastColor
              }}
              clickable
              onClick={(event) => {
                event.stopPropagation()

                if (data.masterIndicator) {
                  if (data.status !== '1008' && data.status !== '1005') {
                    history.push(`${'/ace-highway/shipment'}/edit/${data.id}`, { step: 2, status: data.status, isMaster: data.masterIndicator, scn: data.scn })
                  }
                } else if (data.headerId) {
                  setQuickGlanceResponseLogDialog({ open: true, shipment: data })                  
                }
              }}
            />
          </Tooltip>
        )
      }
    },
    {
      field: 'tripNo',
      title: translatedTextsObject.tripNo,
      sortKey: 'tripNo',
      render: (data) => {
        var editUrl = `${'/ace-highway/manifest'}/edit/${data.headerId}`

        return (<div style={{ 'text-decoration': 'underline', cursor: 'pointer' }} onClick={(event) => {
          event.stopPropagation()
          history.push(editUrl)
        }}>{data.tripNo}</div>)
      }
    }
  ]

  const filters = [
    {
      label: translatedTextsObject.masterShipmentId,
      type: 'textfield',
      name: 'masterShipmentId',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.scn,
      type: 'textfield',
      name: 'scn',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.pointOfLoading,
      type: 'textfield',
      name: 'pointOfLoading',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.arrivalDate,
      type: 'daterange',
      name: 'arrivalDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.shipperName,
      type: 'textfield',
      name: 'shipperName',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.headerId,
      type: 'textfield',
      name: 'headerId',
      operatorType: EQUAL
    },
    {
      label: translatedTextsObject.consigneeName,
      type: 'textfield',
      name: 'consigneeName',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.tripNo,
      type: 'textfield',
      name: 'tripNo',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.entryNo,
      type: 'textfield',
      name: 'brokerEntryNo',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.createdDate,
      type: 'daterange',
      name: 'createdDate',
      operatorType: BETWEEN
    },
    {
      label: 'Last Modified Date',
      type: 'daterange',
      name: 'updatedDate',
      operatorType: BETWEEN
    },
    {
      label: 'Submission Date',
      type: 'daterange',
      name: 'submittedDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.responseDate,
      type: 'daterange',
      name: 'responseDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.status,
      type: 'checkbox',
      name: 'status',
      operatorType: IN,
      options: [
        {
          label: 'Draft',
          value: 'draft',
          filterValue: {
            value: '1005'
          }
        },
        {
          label: 'Accepted',
          value: 'accepted',
          filterValue: {
            value: '1000'
          }
        },
        {
          label: 'Pending Entry Number',
          value: 'pending',
          filterValue: {
            value: 'PENDING'
          }
        },
        {
          label: 'Sent',
          value: 'sent',
          filterValue: {
            value: '1008'
          }
        },
        {
          label: 'Rejected',
          value: 'rejected',
          filterValue: {
            value: '1001'
          }
        },
        {
          label: 'Preliminary',
          value: 'preliminary',
          filterValue: {
            value: '1002'
          }
        },
        {
          label: 'Status Notification',
          value: 'status_notification',
          filterValue: {
            value: '1006'
          }
        },
        {
          label: 'Cancellation Accepted',
          value: 'cancellation_accepted',
          filterValue: {
            value: '1003'
          }
        },
        {
          label: 'Cancellation Rejected',
          value: 'cancellation_rejected',
          filterValue: {
            value: '1004'
          }
        },
        {
          label: 'Modification Rejected',
          value: 'modification_rejected',
          filterValue: {
            value: '1007'
          }
        }
      ]
    },
    {
      label: translatedTextsObject.shipmentType,
      type: 'checkbox',
      name: 'shipmentType',
      operatorType: IN,
      options: [
        {
          label: 'PAPS / Regular',
          value: 'REGULAR',
          filterValue: {
            value: 'REGULAR'
          }
        },
        {
          label: 'BRASS / Inline',
          value: 'BRASS',
          filterValue: {
            value: 'BRASS'
          }
        },
        {
          label: 'ACE In - bond',
          value: 'IN-BOND',
          filterValue: {
            value: 'IN-BOND'
          }
        },
        {
          label: 'Section 321',
          value: 'SEC 321',
          filterValue: {
            value: 'SEC 321'
          }
        },
        {
          label: 'Form 3299',
          value: '3299',
          filterValue: {
            value: '3299'
          }
        },
        {
          label: 'Form 7523',
          value: '7523',
          filterValue: {
            value: '7523'
          }
        },
        {
          label: 'Form 3311',
          value: '3311',
          filterValue: {
            value: '3311'
          }
        },
        {
          label: 'Intangibles',
          value: 'INTANGIBLE',
          filterValue: {
            value: 'INTANGIBLE'
          }
        },
        {
          label: 'Free Astray',
          value: 'ASTRAY',
          filterValue: {
            value: 'ASTRAY'
          }
        },
        {
          label: "Int'l Mail Shipment",
          value: 'I-MAIL',
          filterValue: {
            value: 'I-MAIL'
          }
        }
      ]
    }
  ]

  function handleCloneShipment(data) {
    createRecord.execute(
      AceHighwayShipmentApiUrls.CLONE,
      data,
      (data) => {
        const shipmentData = data.partyInfo.map(
          (partyInfo, index) => ({ ...partyInfo, _id: index + 1 })
        )
        data.partyInfo = shipmentData

        const goodsData = data.goodsDetails.map(
          (goodsDetails, index) => ({ ...goodsDetails, _id: index + 1 })
        )
        data.goodsDetails = goodsData

        history.push(pathMap.ACE_HIGHWAY_SHIPMENT_ADD_VIEW, data)
      },
      (error) => {
        console.log(error)
      }
    )
  }

  function handleMassDeleteShipment() {
    if (massDeleteConfirmDialog.idList) {
      setMassDeleteConfirmDialog({ open: false })
      sweetalert("Shipment(s) deletion in progress...", "", "success");
      securedSendRequest.execute('POST',
        AceHighwayShipmentApiUrls.MASS_DELETE,
        massDeleteConfirmDialog.idList,
        () => {
          showNotification('success', 'Mass delete succeeded')
          setMassDeleteConfirmDialog({ open: false, idList: null })
          tableRef.current.performRefresh()
        },
        (error) => {
          showNotification('error', 'Something went wrong. Mass delete failed.')
          console.log(error)
        }
      )
    }
  }

  function handleDeleteShipment() {
    if (confirmDialog.shipment) {
      deleteRecord.execute(
        AceHighwayShipmentApiUrls.DELETE,
        confirmDialog.shipment,
        () => {
          showNotification('success', 'Delete succeeded')
          setConfirmDialog({ open: false, shipment: null })
          tableRef.current.performRefresh()
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }

  async function exportShipment(data) {
    if (data.length > 0) {
      const config = { responseType: 'blob' }

      securedSendRequest.execute('POST', AceHighwayShipmentApiUrls.EXPORT_SHIPMENT, data,
        (response) => {
          let blob = new Blob([response.data])

          let first = 'ACEHW_SHIPMENT_EXPORT_'
          let curDate = format(new Date(), 'yyyyMMddHHmmss')
          let filename = first + curDate + '.csv'
          let url = window.URL.createObjectURL(blob)
          let a = document.createElement('a')
          a.href = url
          a.download = filename
          a.click()
        },
        (error) => console.error(error),
        undefined,
        config,
        null
      )
    }
  }
  

  return (
    <>
      <Table
        actions={topBarAction}
        columns={columns}
        compact
        exportData={{ url: AceHighwayShipmentApiUrls.EXPORT }}
        fetch={{ url: AceHighwayShipmentApiUrls.CUSTOM_SEARCH }}
        fetchFilters={[
          { field: 'status', operator: NOT_EQUAL, value: '1002' },
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]}
        filters={filters}
        onRowClick={(rowData) => {
        if(rowData.status === '1008'){
            history.push(`${location.pathname}/view/${rowData.id}`)
        }else{
            history.push(`${location.pathname}/edit/${rowData.id}`)
          }
         }
        }
        rowActions={[
          {
            disabled: (rowData) => rowData.status === '1008',
            icon: <FontAwesomeIcon icon={['fal', 'pen']} />,
            label: translatedTextsObject.editButton,
            onClick: (rowData) =>
              history.push(`${location.pathname}/edit/${rowData.id}`),
            tooltip: (rowData) =>
              rowData.status === '1008'
                ? translatedTextsObject.editSentRecords
                : null
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'copy']} />,
            label: translatedTextsObject.cloneButton,
            onClick: handleCloneShipment
          },
          {
            disabled: (rowData) => rowData.status !== '1005',
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            label: translatedTextsObject.deleteButton,
            onClick: (rowData) => setConfirmDialog({ open: true, shipment: rowData }),
            tooltip: (rowData) =>
              rowData.status !== '1005'
                ? 'Only draft status can be deleted'
                : null
          },
          {
            disabled: (rowData) =>
              rowData.status !== '1000' || !rowData.masterIndicator,
            label: translatedTextsObject.cancelButton,
            icon: <FontAwesomeIcon icon={['fal', 'ban']} />,
            onClick: preCancel,
            tooltip: (rowData) =>
              rowData.status !== '1000'
                ? 'Only Accepted status can be cancelled'
                : !rowData.masterIndicator ? 'Only Master Shipment can be cancelled':null
          }
        ]}
        showNotification={showNotification}
        sortConfig={{
          type: 'column',
          defaultField: 'createdDate',
          defaultDirection: 'DESC'
        }}
        persistSettings
        tableRef={tableRef}
        checkboxSelection
        selectActions={[
          {
            // disabled: (rows) => rows.length > 500,
            icon: <FontAwesomeIcon icon={['fal', 'link']} />,
            label: "Export Shipment(s)",
            onClick: (rows) => {
              if (rows.length > 500) {
                sweetalert("Maximum of 500 records per export.", "", "error");
              } else {
                sweetalert("Exporting Shipment in the background. The report will be downloaded automatically once ready.", "", "success");
                return exportShipment(rows);
              }
            },
            tooltip: (rows) => rows.length > 500 ? "Maximum of 500 records per export" : null
          },
          {
            disabled: (rows) => {
              let disable = false;
              rows.forEach(e => {
                if (e.status !== '1005') { disable = true }
              })
              return disable;
            },
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            label: "Mass Delete",
            tooltip: (rows) => {
              let description = null;
              rows.forEach(e => {
                if (e.status !== '1005') { description = 'Only draft status can be deleted' }
              })
              return description;
            },
            buttonProps: {
              size: 'medium',
              color: 'secondary',
              style: { color: theme.palette.error.main, minWidth: 'auto' }
            },
            onClick: (rows) => {
              let idList = rows.map(e => e.id)
              setMassDeleteConfirmDialog({ open: true, idList: idList });
            }
          }
        ]}
      />
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialog.open}
        closeDialog={() => setConfirmDialog({ open: false, shipment: null })}
        confirmDialog={handleDeleteShipment}
        content="Items that you delete can't be restored. Are you sure about this?"
        okMsg='Yes, delete'
        cancelMsg='No, take me back'
        title={translatedTextsObject.deleteButton}
      />
      <ConfirmDialog
        isConfirmDialogOpen={massDeleteConfirmDialog.open}
        closeDialog={() => setMassDeleteConfirmDialog({ open: false, idList: null })}
        confirmDialog={handleMassDeleteShipment}
        content="Items that you delete can't be restored. Are you sure about this?"
        okMsg='Yes, delete'
        cancelMsg='No, take me back'
        title={translatedTextsObject.deleteButton}
      />
      <ShipmentXMLUploadDialog
        open={uploadXMLShipmentDialog}
        onClose={() => setUploadXMLShipmentDialog({ open: false, tableRef: null })}
        showNotification={showNotification}
      />
      <QuickGlanceResponseLogDialog
        getLookupValue={getLookupValue}
        shipment={quickGlanceResponseLogDialog.shipment}
        onClose={() => setQuickGlanceResponseLogDialog({ open: false, shipment: null })}
        open={quickGlanceResponseLogDialog.open}
        translatedTextsObject={translatedTextsObject}
      />
      <ChargeTypeDialog
        chargeTypes={chargeTypes}
        onClose={() => setSubmitDialog({ open: false, items: [] })}
        onSubmit={(data) => {
          handleCancelMasterShipment(data)
        }}
        open={submitDialog.open}
        items={submitDialog.items}
      />
    </>
  )
}

export default TablePage

function QuickGlanceResponseLogDialog(props) {
  const { getLookupValue, shipment, onClose, open, translatedTextsObject } = props
    const { securedSendRequest } = useServices()
    const theme = useTheme()
    const [statusList, setStatusList] = useState([])
    const [redirectUrl, setRedirectUrl] = useState()
  
    const redirectData = {
      manifestData: {...shipment},
      step: "2"
    }
  
  let content = shipment ? (
      <Grid item xs={12}>
            <ResponseLog
              disableView
              getLookupValue={getLookupValue}
              manifestId={shipment.headerId}
               manifestPort={shipment.firstExpectedPort}
              noOfShipment={shipment.noOfShipment}
            />   
        </Grid>
    ) : null
      return (
      <CngDialog
        dialogContent={content}
        dialogAction={<CngButton onClick={onClose}>OK</CngButton>}
        dialogTitle='Response log '
        fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
        fullWidth
        maxWidth='lg'
        onClose={onClose}
        open={open}
        shouldShowCloseButton
      />
    )
  
  }
