import {
  Box,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import { components, constants, useServices, useTranslation } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import AceHighwayShipmentKeys from 'src/constants/locale/key/AceHighwayShipment'
import AceHighwayShipmentApiUrls from 'src/apiUrls/AceHighwayShipmentApiUrls'
import NaCodeMaintenanceApiURLs from 'src/apiUrls/NaCodeMaintenanceApiUrls'
import pathMap from 'src/paths/pathMap'
import ShipmentViewContent from './ShipmentViewContent'
import ConfirmDialog from '../../common/ConfirmDialog'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const {
  button: { CngButton },
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  filter: { EQUAL, IN }
} = constants

function ViewPage({ history, showNotification }) {
  const { id } = useParams()

  const [data, setData] = useState(null)
  const [lookups, setLookups] = useState(null)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const { createRecord, deleteRecord, fetchRecord, fetchRecords } =
    useServices()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_SHIPMENT)
  const translatedTextsObject = makeTranslatedTextsObject()

  useEffect(() => {
    Promise.all([
      // Data
      fetchRecord.execute(
        AceHighwayShipmentApiUrls.GET,
        id,
        (res) => res.content[0]
      ),
      // Status
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACE_HW_STATUS' }],
        undefined,
        'code'
      ),
      // Point of loading
      fetchRecords.execute(
        NaCodeMaintenanceApiURLs.GET,
        {
          filters: [
            { field: 'set_indicator', operator: EQUAL, value: 'ACEHWY_POL' }
          ],
          customData: { codeMType: 'DocPortCode' }
        },
        (res) => {
          const result = {}

          res.content.forEach((item) => {
            result[item.code] = item.descriptionEn
          })

          return result
        }
      ),
      // Countries
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [
          {
            field: 'codeType',
            operator: EQUAL,
            value: 'ACIHWY_MANIFEST_COUNTRY'
          }
        ],
        undefined,
        'code'
      ),
      // Country states
      fetchRecords.execute(
        NaCodeMaintenanceApiURLs.GET,
        {
          filters: [{ field: 'indicator', operator: 'EQUAL', value: 'ACE' }],
          customData: { codeMType: 'CountryStateCode' }
        },
        (res) => res.content
      ),
      // Party type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACE_HW_PARTY_TYPE' }],
        undefined,
        'code'
      ),
      // Entry type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [
          {
            field: 'codeType',
            operator: IN,
            value: 'ACE_HW_ENTRY_TYPE_IN_BOND|ACE_HW_ENTRY_TYPE_SEC'
          }
        ],
        undefined,
        'code'
      )
    ]).then(
      ([
        data,
        status,
        pointOfLoading,
        countries,
        countryStates,
        partyType,
        entryType
      ]) => {
        if (data === undefined) {
          showNotification('error', 'Data not found.')
          history.push(pathMap.ACE_HIGHWAY_SHIPMENT_LIST_VIEW)
        }

        const states = Object.keys(countries).reduce((acc, countryCode) => {
          acc[countryCode] = countryStates.filter(
            (state) => state.code === countryCode
          )

          return acc
        }, {})

        setData(data)
        setLookups({
          status,
          pointOfLoading,
          countries,
          countryStates: states,
          partyType,
          entryType
        })
      }
    )
  }, [])

  function makeTranslatedTextsObject() {
    let editButton = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayShipmentKeys.EDIT_BUTTON
    )
    let cloneButton = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayShipmentKeys.CLONE_BUTTON
    )
    let deleteButton = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayShipmentKeys.DELETE_BUTTON
    )

    return { editButton, cloneButton, deleteButton }
  }

  if (data === null) {
    return <CircularProgress />
  }

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  function getCountryStateLabel(country, state) {
    if (!lookups || !country || !state) return state

    // Check if country code exists in the lookup
    if (Object.keys(lookups.countries).includes(country)) {
      const result = lookups.countryStates[country].find(
        (countryState) => countryState.intlcode === state
      )

      return result ? result.descriptionEn : state
    } else {
      return state
    }
  }

  function handleCloneShipment() {
    createRecord.execute(
      AceHighwayShipmentApiUrls.CLONE,
      data,
      (data) => {
        history.push(pathMap.ACE_HIGHWAY_SHIPMENT_ADD_VIEW, data)
      },
      (error) => {
        console.log(error)
      }
    )
  }

  function handleDeleteShipment() {
    deleteRecord.execute(
      AceHighwayShipmentApiUrls.DELETE,
      data,
      () => {
        showNotification('success', 'Delete succeeded')
        history.push(pathMap.ACE_HIGHWAY_SHIPMENT_LIST_VIEW)
      },
      (error) => {
        console.log(error)
      }
    )
  }

  return (
    <>
      <Card>
        <CardContent>
          <ShipmentViewContent
            data={data}
            getLookupValue={getLookupValue}
            getCountryStateLabel={getCountryStateLabel}
          />
        </CardContent>
        <Divider />
        <CardActions style={{ padding: 16, justifyContent: 'space-between' }}>
          <CngButton
            color='secondary'
            onClick={() => history.push(pathMap.ACE_HIGHWAY_SHIPMENT_LIST_VIEW)}
          >
            Back to shipment list
          </CngButton>
          <Box display='flex' style={{ gap: 16 }}>
            {data.status === '1005' && (
              <CngButton
                disabled={data.status !== '1005'}
                onClick={() => setConfirmDialogOpen(true)}
                startIcon={
                  <FontAwesomeIcon icon={['fal', 'trash']} size='sm' />
                }
                variant='outlined'
              >
                {translatedTextsObject.deleteButton}
              </CngButton>
            )}
            <CngButton
              onClick={handleCloneShipment}
              startIcon={<FontAwesomeIcon icon={['fal', 'copy']} size='sm' />}
              variant='outlined'
            >
              {translatedTextsObject.cloneButton}
            </CngButton>
            {data.status !== '1008' && (
              <CngButton
                color='primary'
                disabled={data.status === '1008'}
                startIcon={<FontAwesomeIcon icon={['fal', 'pen']} size='sm' />}
                onClick={() =>
                  history.push(`${pathMap.ACE_HIGHWAY_SHIPMENT}/edit/${id}`)
                }
              >
                {translatedTextsObject.editButton}
              </CngButton>
            )}
          </Box>
        </CardActions>
      </Card>
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialogOpen}
        closeDialog={() => setConfirmDialogOpen(false)}
        confirmDialog={handleDeleteShipment}
        content="Items that you delete can't be restored. Are you sure about this?"
        okMsg='Yes, delete'
        cancelMsg='No, take me back'
        title={translatedTextsObject.deleteButton}
      />
    </>
  )
}

export default ViewPage
