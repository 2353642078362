import React, { useEffect, useMemo, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { components, useServices } from 'cng-web-lib'
import { useFormContext, useWatch } from 'react-hook-form'
import { Grid, Box, Typography } from '@material-ui/core'
import { fetchUser } from "src/views/userprofile/UserProfileService.js";
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import makeValidationSchema from './MakeValidationSchema'
import PartyInfoSection from './Sections/PartyInfoSection'
import ShipmentDetailsSection from './Sections/ShipmentDetailsSection'
import GoodsDetailsSection from './Sections/GoodsDetailsSection'
import moment from 'moment-timezone'
import CryptoJS from 'crypto-js';

const {
  button: { CngIconButton },
  form: {
    field: {
      CngTextField,
      CngDateField,
      CngCodeMasterAutocompleteField
    },
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  shipmentType: "REGULAR",
  entryType: "",
  scn: "",
  carrierScac: "",
  acquittalNo: "",
  pointOfLoading: "",
  fdaIndicator: "",
  splitIndicator: false,
  masterIndicator: false,
  quantity: 0,
  serviceType: "",
  inBond7512No: "",
  masterInBondNo: "",
  mexicanPedimento: "",
  foreignDestination: "",
  inBondDestination: "",
  firmsCode: "",
  exitDate: null,
  partyInfo: [],
  goodsDetails: [],
  integration: "NANG",
  headerId: "",
  status: "",
  oriCreatedBy: "",
  oriUpdatedBy: "",
  oriSubmittedBy: "",
  firstExpectedCode: ""
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap, manifestCarrierScac }) {
  const { getValues, setValue } = useFormContext()
  const location = useLocation();
  const editPage = location.pathname.toString().indexOf("edit") != -1 ? true : false

  const splitShipment = useWatch({ name: 'splitIndicator' })

  useWatch("fileForUserId");
  useWatch("fileForUserLoginId")
  useWatch("fileForUserPartyId");
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue("fileForUserId", fileForUserDetails.fileForUserId)
      setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
      setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)
    }

    let partyInfo = getValues('partyInfo');

    var encryptedBase64Key = "Y3JpbXNvbmxvZ2ljMTIzNA==";
    var parsedBase64Key = CryptoJS.enc.Base64.parse(encryptedBase64Key);

    let enableEncryption = `${process.env.REACT_APP_NA_SHOULD_ENABLE_ENCRYPTION}`

    if (enableEncryption == "true") {
      partyInfo.map(partyInfo => {
        if (partyInfo.partyIdentifierValue && partyInfo.partyIdentifierValue.includes("=")) {
          var decryptedData = CryptoJS.AES.decrypt(partyInfo.partyIdentifierValue, parsedBase64Key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
          });

          var decryptedText = decryptedData.toString(CryptoJS.enc.Utf8);
          partyInfo.partyIdentifierValue = decryptedText
        }
      })
    }
    setValue("partyInfo", partyInfo)
  }, []);

  const [user, setUser] = useState([]);
  const { securedSendRequest } = useServices();
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    fetchUser(setUser, securedSendRequest, fileForUserDetails);
  }, []);

  const memoedShipmentDetailsSection = useMemo(() => (
    <ShipmentDetailsSection disabled={disabled} shouldHideMap={shouldHideMap} user={user} manifestCarrierScac={manifestCarrierScac} editPage={editPage} />
  ), [user])

  const memoedPartyInfoSection = useMemo(() => (
    <PartyInfoSection />
  ), [])

  const memoedGoodsDetailsSection = useMemo(() => (
    <GoodsDetailsSection />
  ))

  return (
    <>
      <Box marginBottom={2}>
        <Typography variant='h4' style={{ fontWeight: 700 }}>
          Shipment Information
        </Typography>
        <Typography variant='caption'>
          <Typography variant='caption' color='error'>*</Typography>Mandatory fields
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          {memoedShipmentDetailsSection}
        </Grid>

        {(!splitShipment) && (
          <>
            {memoedPartyInfoSection}
            {memoedGoodsDetailsSection}
          </>
        )}
      </Grid>
    </>
  )
}

function toClientDataFormat(serverData) {
  let localData = serverData;
  if(localData && localData.scn){
    localData.carrierScac = localData.scn.substring(0, 4);
  }
  
  if (localData.fdaIndicator === undefined && localData.length > 0) { //when update multiple shipment
    localData.forEach((datum) => {

      if (datum.exitDate !== null)
        datum.exitDate = moment(datum.exitDate).tz("Canada/Eastern").format('YYYY-MM-DD');

      if (datum.fdaIndicator == "Y" || datum.fdaIndicator == true) {
        datum.fdaIndicator = true
      } else {
        datum.fdaIndicator = false
      }

      if (datum.partyInfo != null && datum.partyInfo.length > 0) {
        for (let i = 0; i < datum.partyInfo.length; i++) {

          //if object value is null then set to empty to resolve text and value overlap issues
          Object.keys(datum.partyInfo[i]).forEach(function (key) {
            if (key != "baseFilterDTO" && key != "errorMessages" && key != "customData") {
              datum.partyInfo[i][key] = datum.partyInfo[i][key] === null ? "" : datum.partyInfo[i][key]
            }
          });

          if (datum.partyInfo[i].ctpatInd == "Y" || datum.partyInfo[i].ctpatInd == true) {
            datum.partyInfo[i].ctpatInd = true
          } else {
            datum.partyInfo[i].ctpatInd = false
          }
        }
      }
    })
  } else { //when update single shipment
    if (localData.exitDate !== null)
      localData.exitDate = moment(localData.exitDate).tz("Canada/Eastern").format('YYYY-MM-DD');

    if (localData.fdaIndicator == "Y" || localData.fdaIndicator == true) {
      localData.fdaIndicator = true
    } else {
      localData.fdaIndicator = false
    }

    if (localData.partyInfo != null && localData.partyInfo.length > 0) {
      for (let i = 0; i < localData.partyInfo.length; i++) {

        //if object value is null then set to empty to resolve text and value overlap issues
        Object.keys(localData.partyInfo[i]).forEach(function (key) {
          if (key != "baseFilterDTO" && key != "errorMessages" && key != "customData") {
            localData.partyInfo[i][key] = localData.partyInfo[i][key] === null ? "" : localData.partyInfo[i][key]
          }
        });

        if (localData.partyInfo[i].ctpatInd == "Y" || localData.partyInfo[i].ctpatInd == true) {
          localData.partyInfo[i].ctpatInd = true
        } else {
          localData.partyInfo[i].ctpatInd = false
        }
      }

    }
  }
  return localData;
}

function toServerDataFormat(localData) {
  if (localData.exitDate !== null)
    localData.exitDateString = moment(localData.exitDate).format('YYYY-MM-DD');

  if (localData.fdaIndicator == true || localData.fdaIndicator == "Y") {
    localData.fdaIndicator = "Y"
  } else {
    localData.fdaIndicator = "N"
  }

  if (localData.partyInfo != null && localData.partyInfo.length > 0) {
    for (let i = 0; i < localData.partyInfo.length; i++) {
      if (localData.partyInfo[i].ctpatInd == true || localData.partyInfo[i].ctpatInd == "Y") {
        localData.partyInfo[i].ctpatInd = "Y"
      } else {
        localData.partyInfo[i].ctpatInd = "N"
      }

      if (localData.partyInfo[i].partyType == "CB" && localData.partyInfo[i].partyIdentifierValue != null) {
        localData.partyInfo[i].partyIdentifierCode = "275"
      }
    }

  }

  return localData;
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
