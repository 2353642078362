import React, { useCallback } from 'react'
import { useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import ISF10Keys from '../../../constants/locale/key/AceIsf'

const AceIsfTranslationContext = React.createContext()

const AceIsfTranslationConsumer = AceIsfTranslationContext.Consumer

const AceIsfTranslationProvider = ({ children }) => {
    const { translate } = useTranslation([
        Namespace.ACE_ISF
    ])

    const translation = {
        ACE_ISF: {
            title: translate(Namespace.ACE_ISF, ISF10Keys.TITLE),
            titleIsf5: translate(Namespace.ACE_ISF, ISF10Keys.TITLE_ISF5),
            isfShipmentAndFilerReference: translate(Namespace.ACE_ISF, ISF10Keys.ISF_SHIPMENT_AND_FILER_REFERENCE),
            shipmentReference: translate(Namespace.ACE_ISF, ISF10Keys.SHIPMENT_REFERENCE),
            myReference: translate(Namespace.ACE_ISF, ISF10Keys.MY_REFERENCE),
            isfShipment: translate(Namespace.ACE_ISF, ISF10Keys.ISF_SHIPMENT),
            filerReference: translate(Namespace.ACE_ISF, ISF10Keys.FILER_REFERENCE),
            commercialParty: translate(Namespace.ACE_ISF, ISF10Keys.COMMERCIAL_PARTY),
            deliveryLocation: translate(Namespace.ACE_ISF, ISF10Keys.DELIVERY_LOCATION),
            htsDetails: translate(Namespace.ACE_ISF, ISF10Keys.HTS_DETAILS),
            containerInfo: translate(Namespace.ACE_ISF, ISF10Keys.CONTAINER_INFO),
            shipmentType: translate(Namespace.ACE_ISF, ISF10Keys.SHIPMENT_TYPE),
            modeOfTransport: translate(Namespace.ACE_ISF, ISF10Keys.MODE_OF_TRANSPORT),
            entryNo: translate(Namespace.ACE_ISF, ISF10Keys.ENTRY_NO),
            importerDocType: translate(Namespace.ACE_ISF, ISF10Keys.IMPORTER_DOC_TYPE),
            importerRefNo: translate(Namespace.ACE_ISF, ISF10Keys.IMPORTER_REF_NO),
            dateOfBirth: translate(Namespace.ACE_ISF, ISF10Keys.DATE_OF_BIRTH),
            countryIssuance: translate(Namespace.ACE_ISF, ISF10Keys.COUNTRY_ISSUANCE),
            name: translate(Namespace.ACE_ISF, ISF10Keys.NAME),
            cbpBond: translate(Namespace.ACE_ISF, ISF10Keys.CBP_BOND),
            bondType: translate(Namespace.ACE_ISF, ISF10Keys.BOND_TYPE),
            bondHolderDocType: translate(Namespace.ACE_ISF, ISF10Keys.BOND_HOLDER_DOC_TYPE),
            bondHolderDocNo: translate(Namespace.ACE_ISF, ISF10Keys.BOND_HOLDER_DOC_NO),
            bondTerm: translate(Namespace.ACE_ISF, ISF10Keys.BOND_TERM),
            bondTermRefNo: translate(Namespace.ACE_ISF, ISF10Keys.BOND_TERM_REF_NO),
            bondTermSuretyCode: translate(Namespace.ACE_ISF, ISF10Keys.BOND_TERM_SURETY_CODE),
            agentCode: translate(Namespace.ACE_ISF, ISF10Keys.AGENT_CODE),
            scac: translate(Namespace.ACE_ISF, ISF10Keys.SCAC),
            actionCode: translate(Namespace.ACE_ISF, ISF10Keys.ACTION_CODE),
            actionReasonCode: translate(Namespace.ACE_ISF, ISF10Keys.ACTION_REASON_CODE),
            refBookingNo: translate(Namespace.ACE_ISF, ISF10Keys.REF_BOOKING_NO),
            portOfLading: translate(Namespace.ACE_ISF, ISF10Keys.PORT_OF_LADING),
            estimateLoadingDate: translate(Namespace.ACE_ISF, ISF10Keys.ESTIMATE_LOADING_DATE),
            cbpTransactionNo: translate(Namespace.ACE_ISF, ISF10Keys.CBP_TRANSACTION_NO),
            cbpTransactionNoInfo: translate(Namespace.ACE_ISF, ISF10Keys.CBP_TRANSACTION_NO_INFO),
            responseDateTime: translate(Namespace.ACE_ISF, ISF10Keys.RESPONSE_DATE_TIME),
            createdDateTime: translate(Namespace.ACE_ISF, ISF10Keys.CREATED_DATE_TIME),
            submittedDateTime: translate(Namespace.ACE_ISF, ISF10Keys.SUBMITTED_DATE_TIME),
            updateButton: translate(Namespace.ACE_ISF, ISF10Keys.UPDATE_BUTTON),
            saveButton: translate(Namespace.ACE_ISF, ISF10Keys.SAVE_BUTTON),
            submitButton: translate(Namespace.ACE_ISF, ISF10Keys.SUBMIT_BUTTON),
            editButton: translate(Namespace.ACE_ISF, ISF10Keys.EDIT_BUTTON),
            cloneButton: translate(Namespace.ACE_ISF, ISF10Keys.CLONE_BUTTON),
            deleteButton: translate(Namespace.ACE_ISF, ISF10Keys.DELETE_BUTTON),
            viewIsf: translate(Namespace.ACE_ISF, ISF10Keys.VIEW_ISF),
            bondNo: translate(Namespace.ACE_ISF, ISF10Keys.BOND_NO),
            masterBL: translate(Namespace.ACE_ISF, ISF10Keys.MASTER_B_L),
            countryUnlading: translate(Namespace.ACE_ISF, ISF10Keys.COUNTRY_UNLADING),
            foreignPortUnlading: translate(Namespace.ACE_ISF, ISF10Keys.FOREIGN_PORT_UNLADING),
            countryDelivery: translate(Namespace.ACE_ISF, ISF10Keys.COUNTRY_DELIVERY),
            foreignPlaceDelivery: translate(Namespace.ACE_ISF, ISF10Keys.FOREIGN_PLACE_DELIVERY),
            status: translate(Namespace.ACE_ISF, ISF10Keys.STATUS),
            submitSuccessMsg: translate(Namespace.ACE_ISF, ISF10Keys.SUBMIT_SUCCESS_MSG),
            modeOfSubmission: translate(Namespace.ACE_ISF, ISF10Keys.MODE_OF_SUBMISSION),
            templateFlag: translate(Namespace.ACE_ISF, ISF10Keys.TEMPLATE_FLAG),
            templateName: translate(Namespace.ACE_ISF, ISF10Keys.TEMPLATE_NAME),
            shipment: translate(Namespace.ACE_ISF, ISF10Keys.Shipment.TITLE),
            headerId: translate(Namespace.ACE_ISF, ISF10Keys.Shipment.HEADER_ID),
            blType: translate(Namespace.ACE_ISF, ISF10Keys.Shipment.BL_TYPE),
            blNo: translate(Namespace.ACE_ISF, ISF10Keys.Shipment.BL_NO),
            blMatched: translate(Namespace.ACE_ISF, ISF10Keys.Shipment.BL_MATCHED),
            party: translate(Namespace.ACE_ISF, ISF10Keys.Party.TITLE),
            saveToPartyMaster: translate(Namespace.ACE_ISF, ISF10Keys.Party.SAVE_TO_PARTY_MASTER),
            docType: translate(Namespace.ACE_ISF, ISF10Keys.Party.DOC_TYPE),
            docNumber: translate(Namespace.ACE_ISF, ISF10Keys.Party.DOC_NUMBER),
            partyName: translate(Namespace.ACE_ISF, ISF10Keys.Party.PARTY_NAME),
            secondaryInfoType: translate(Namespace.ACE_ISF, ISF10Keys.Party.SECONDARY_INFO_TYPE),
            secondaryInfo: translate(Namespace.ACE_ISF, ISF10Keys.Party.SECONDARY_INFO),
            address: translate(Namespace.ACE_ISF, ISF10Keys.Party.ADDRESS),
            city: translate(Namespace.ACE_ISF, ISF10Keys.Party.CITY),
            postalCode: translate(Namespace.ACE_ISF, ISF10Keys.Party.POSTAL_CODE),
            country: translate(Namespace.ACE_ISF, ISF10Keys.Party.COUNTRY),
            province: translate(Namespace.ACE_ISF, ISF10Keys.Party.PROVINCE),
            hts: translate(Namespace.ACE_ISF, ISF10Keys.Hts.TITLE),
            manufacturerPartyId: translate(Namespace.ACE_ISF, ISF10Keys.Hts.MANUFACTURER_PARTY_ID),
            htsNo: translate(Namespace.ACE_ISF, ISF10Keys.Hts.HTS_NO),
            countryOrigin: translate(Namespace.ACE_ISF, ISF10Keys.Hts.COUNTRY_ORIGIN),
            container: translate(Namespace.ACE_ISF, ISF10Keys.Container.TITLE),
            containerNo: translate(Namespace.ACE_ISF, ISF10Keys.Container.CONTAINER_NO),
            descriptionCode: translate(Namespace.ACE_ISF, ISF10Keys.Container.DESCRIPTION_CODE),
            containerInitial: translate(Namespace.ACE_ISF, ISF10Keys.Container.CONTAINER_INITIAL),
            typeCode: translate(Namespace.ACE_ISF, ISF10Keys.Container.TYPE_CODE),
            sizeTypeCode: translate(Namespace.ACE_ISF, ISF10Keys.Container.SIZE_TYPE_CODE),
            transaction: translate(Namespace.ACE_ISF, ISF10Keys.Transaction.TITLE),
            batchId: translate(Namespace.ACE_ISF, ISF10Keys.Transaction.BATCH_ID),
            response: translate(Namespace.ACE_ISF, ISF10Keys.Response.TITLE),
            statusNotification: translate(Namespace.ACE_ISF, ISF10Keys.Response.STATUS_NOTIFICATION),
            statusCode: translate(Namespace.ACE_ISF, ISF10Keys.Response.STATUS_CODE),
            errorCode: translate(Namespace.ACE_ISF, ISF10Keys.Response.ERROR_CODE),
            description: translate(Namespace.ACE_ISF, ISF10Keys.Response.DESCRIPTION),
            remarks: translate(Namespace.ACE_ISF, ISF10Keys.Response.REMARKS),
            dispositionCode: translate(Namespace.ACE_ISF, ISF10Keys.Response.DISPOSITION_CODE),
            receivedDate: translate(Namespace.ACE_ISF, ISF10Keys.Response.RECEIVED_DATE),
            cbpProcessedDate: translate(Namespace.ACE_ISF, ISF10Keys.Response.CBP_PROCESSED_DATE),
            cbpBatchNo: translate(Namespace.ACE_ISF, ISF10Keys.Response.CBP_BATCH_NO),
            transactionNo: translate(Namespace.ACE_ISF, ISF10Keys.Response.TRANSACTION_NO),
            partyDocTypeDetails: translate(Namespace.ACE_ISF, ISF10Keys.PARTY_DOC_TYPE_DETAILS),
            documentDetails: translate(Namespace.ACE_ISF, ISF10Keys.DOCUMENT_DETAILS),
            partyDetails: translate(Namespace.ACE_ISF, ISF10Keys.PARTY_DETAILS),
            manufacturerHtsDetails: translate(Namespace.ACE_ISF, ISF10Keys.MANUFACTURER_HTS_DETAILS),
            partyType: translate(Namespace.ACE_ISF, ISF10Keys.PARTY_TYPE),
            shipmentSubType: translate(Namespace.ACE_ISF, ISF10Keys.SHIPMENT_SUB_TYPE),
            estimatedValue: translate(Namespace.ACE_ISF, ISF10Keys.ESTIMATED_VALUE),
            estimatedQuantity: translate(Namespace.ACE_ISF, ISF10Keys.ESTIMATED_QUANTITY),
            unitOfMeasure: translate(Namespace.ACE_ISF, ISF10Keys.UNIT_OF_MEASURE),
            estimatedWeight: translate(Namespace.ACE_ISF, ISF10Keys.ESTIMATED_WEIGHT),
            weightQualifiers: translate(Namespace.ACE_ISF, ISF10Keys.WEIGHT_QUALIFIERS),
            carnetNumber: translate(Namespace.ACE_ISF, ISF10Keys.CARNET_NUMBER),
            editISF10: translate(Namespace.ACE_ISF, ISF10Keys.EDIT_ISF10),
            printISF10: translate(Namespace.ACE_ISF, ISF10Keys.PRINT_ISF10),
            editISF5: translate(Namespace.ACE_ISF, ISF10Keys.EDIT_ISF5),
            dobDateRegx: translate(Namespace.ACE_ISF, ISF10Keys.Validation.DOB_DATE_REGEX),
            partyId: translate(Namespace.ACE_ISF, ISF10Keys.Party.PARTY_ID),
            partyIdLookup: translate(Namespace.ACE_ISF, ISF10Keys.Party.PARTY_ID_LOOKUP),
            editSentRecords: translate(Namespace.ACE_ISF, ISF10Keys.EDIT_SENT_RECORDS),
            deleteRecords: translate(Namespace.ACE_ISF, ISF10Keys.DELETE_RECORDS),
            sameAs: translate(Namespace.ACE_ISF, ISF10Keys.SAME_AS),
            printISF5: translate(Namespace.ACE_ISF, ISF10Keys.PRINT_ISF5),

        }
    }

    const getTranslatedText = useCallback((namespace, key) => {
        if (!namespace || !key) return undefined

        return translation?.[namespace]?.[key] || undefined
    }, [])

    return (
        <AceIsfTranslationContext.Provider value={{ translation, getTranslatedText }}>
            {children}
        </AceIsfTranslationContext.Provider>
    )
}

export default AceIsfTranslationProvider

export { AceIsfTranslationContext, AceIsfTranslationConsumer }
