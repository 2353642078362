import React, { useRef, useEffect, useContext } from 'react'
import { components } from 'cng-web-lib'
import { Grid, Card, Box, Tooltip } from '@material-ui/core'
import { useFormContext, useWatch } from 'react-hook-form'
import { ACEHighwayTranslationContext } from '../../contexts/ACEHighwayTranslationContext'
import CngSection from 'src/components/cngcomponents/CngSection'
import PartyInfoFormProperties from '../PartyInfoFormProperties'

const { button: { CngIconButton } } = components

const { initialValues } = PartyInfoFormProperties.formikProps

function PartyInfoSection() {
    const { getValues, setValue } = useFormContext()
    const { getTranslatedText } = useContext(ACEHighwayTranslationContext)
    const splitShipment = useWatch({ name: 'splitIndicator' })
    const partyInfo = useWatch({ name: 'partyInfo' })
    const lastIndex = useRef(partyInfo.length - 1)

    // Push party info default value with crew type 'Shipper' or 'Consignee' if there isn't any
    useEffect(() => {
        const { initialValues } = PartyInfoFormProperties.formikProps

        if (!splitShipment) {
            if (partyInfo.length === 0) {
                setValue('partyInfo', [
                    { ...initialValues, partyType: 'OS', _id: 1 },
                    { ...initialValues, partyType: 'CN', _id: 2 }
                ])
            } else {
                const hasShipper = partyInfo.findIndex(party => party.partyType === 'OS') !== -1
                const hasConsignee = partyInfo.findIndex(party => party.partyType === 'CN') !== -1

                if (!hasShipper || !hasConsignee) {
                    const result = [...getValues('partyInfo')]

                    if (!hasShipper) {
                        result.push({ ...initialValues, partyType: 'OS' })
                    } else if (!hasConsignee) {
                        result.push({ ...initialValues, partyType: 'CN' })
                    }

                    setValue('partyInfo', result.map((party, index) => ({ ...party, _id: index + 1 })))
                }
            }
        }
    }, [splitShipment, partyInfo])


    function handleAddPartyInfo(data) {
        const partyInfo = [...getValues('partyInfo'), { ...data, _id: ++lastIndex.current }]
        setValue('partyInfo', partyInfo)
    }

    function handleDeletePartyInfo(data) {
        const partyInfo = [...getValues('partyInfo')].filter((party) =>
            party.id ? party.id !== data.id : party._id !== data._id
        )
        setValue('partyInfo', partyInfo)
    }

    return (
        <Grid item xs={12}>
            <CngSection title='Shipper / Consignee / Other Party' helperText={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'partySectionHelper')}>
                <Grid container spacing={2}>
                    {partyInfo.map((party, index) => (
                        <Grid key={index} item xs={12}>
                            <Card variant='outlined'>
                                <Box padding={2}>
                                    <Grid container justify='space-between' spacing={1}>
                                        <Grid item xs={12}>
                                            <PartyInfoFormProperties.Fields index={index} pointOfLoading={getValues("pointOfLoading")} firstExpectedCode={getValues("firstExpectedCode")} scn={getValues("scn")} />
                                        </Grid>
                                        <Grid item xs='auto'>
                                            <Grid container spacing={1}>
                                                <Grid item>
                                                    <Tooltip placement='bottom' title='Add'>
                                                        <span>
                                                            <CngIconButton
                                                                icon={['fal', 'plus']} size='small' type='outlined'
                                                                onClick={() => handleAddPartyInfo(initialValues)}
                                                            />
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item>
                                                    <Tooltip placement='bottom' title='Clone'>
                                                        <span>
                                                            <CngIconButton
                                                                icon={['fal', 'copy']} size='small' type='outlined'
                                                                onClick={() => handleAddPartyInfo({ ...party, id: undefined })}
                                                            />
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item>
                                                    <Tooltip placement='bottom' title='Delete'>
                                                        <span>
                                                            <CngIconButton
                                                                icon={['fal', 'trash']} size='small' type='outlined'
                                                                onClick={() => handleDeletePartyInfo(party)}
                                                            />
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </CngSection>
        </Grid>
    )

}

export default PartyInfoSection