import React, { useEffect, useState, useContext } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { components } from 'cng-web-lib'
import { Grid } from '@material-ui/core'
import { ACEHighwayTranslationContext } from '../../contexts/ACEHighwayTranslationContext'
import { NaAceHwyPOEPortAutocompleteField, NaAceHwyPOLPortAutocompleteField } from 'src/components/na'
import CngSection from 'src/components/cngcomponents/CngSection'
import Switch from 'src/components/Switch'

const {
    form: {
        field: {
            CngTextField,
            CngDateField,
            CngCodeMasterAutocompleteField,
            CngSelectField
        },
    },
    CngGridItem
} = components

function ShipmentDetailsSection(props) {
    const { disabled, shouldHideMap, user, manifestCarrierScac, editPage } = props
    const { getValues, setValue } = useFormContext();
    const { getTranslatedText } = useContext(ACEHighwayTranslationContext)
    const [scacList, setScacList] = useState([]);
    const [onChangeScacCode, setOnChangeScacCode] = useState(false)

    const id = useWatch({ name: 'id' })
    const scn = useWatch({ name: 'scn' })
    const entryType = useWatch({ name: 'entryType' })
    const shipmentType = useWatch({ name: 'shipmentType' })
    const splitShipment = useWatch({ name: 'splitIndicator' })
    const masterShipment = useWatch({ name: 'masterIndicator' })

    const [ccnAutoGenerated, setCcnAutoGenerated] = useState(false);

    useEffect(() => {
        var scacCode = '';
        var jsonArray = [];
        if (user && user.userAutoScns) {
            Object.entries(user.scacs).forEach((item) => {
                for (const key in item[1]) {
                    if (key == 'scacCode') {
                        scacCode = item[1][key];
                    }
                    if (key == 'module') {
                        module = item[1][key];
                    }
                }

                if (module == 'ACEHW') {
                    var jsonObject = { text: scacCode, value: scacCode };
                    jsonArray.push(jsonObject);
                }
            })
            setScacList(jsonArray);

            let flag = user.userAutoScns[0];
            setCcnAutoGenerated(flag && flag.autoScn === 'true' && flag.module.includes("ACEHWY"));

            if (!id && manifestCarrierScac && flag && flag.autoScn === 'true' && flag.module.includes("ACEHWY")) {
                setValue("carrierScac", manifestCarrierScac);
                setValue("scn", manifestCarrierScac + getCurrentDateTime());
            }

            if (!manifestCarrierScac && (jsonArray && jsonArray.length === 1)) {
                setValue("carrierScac", jsonArray[0].value);
                if (flag && flag.autoScn === 'true' && scn === '' && flag.module.includes("ACEHWY")) {
                    setValue("scn", jsonArray[0].value + getCurrentDateTime());
                } else {
                    if (scn === '') {
                        setValue("scn", jsonArray[0].value);
                    }
                }
            }
        }
    }, [user])

    useEffect(() => {
        if (manifestCarrierScac && scn === '') {
            setValue("carrierScac", manifestCarrierScac);
            setValue("scn", manifestCarrierScac);
        }
    }, [])

    useEffect(() => {
        if (getValues("fdaIndicator") === "Y") {
            setValue("fdaIndicator", true)
        } else if (getValues("fdaIndicator") === "N") {
            setValue("fdaIndicator", false)
        }
    }, [getValues("fdaIndicator")]);

    useEffect(() => {
        if (splitShipment) {
            setValue('fdaIndicator', false)
            setValue('entryType', '')
            setValue('serviceType', '')
            setValue('acquittalNo', '')
            setValue('inBond7512No', '')
            setValue('masterInBondNo', '')
            setValue('mexicanPedimento', '')
            setValue('foreignDestination', '')
            setValue('inBondDestination', '')
            setValue('firmsCode', '')
            setValue('exitDate', null)
            setValue("partyInfo", [])
            setValue("goodsDetails", [])
        }

        setValue("quantity", null)
    }, [splitShipment]);

    useEffect(() => {
        if (shipmentType !== 'IN-BOND' && shipmentType !== 'SEC 321') {
            setValue('entryType', '')
            setValue('acquittalNo', '')
        }
        if (shipmentType !== 'IN-BOND' && shipmentType !== 'ASTRAY') {
            setValue('exitDate', null)
        }
        if (shipmentType !== 'IN-BOND') {
            setValue('inBond7512No', '')
            setValue('masterInBondNo', '')
            setValue('mexicanPedimento', '')
            setValue('foreignDestination', '')
            setValue('inBondDestination', '')
            setValue('firmsCode', '')
            getValues('goodsDetails').forEach((datum) => {
                datum.harmonizedCode1 = ''
                datum.harmonizedCode2 = ''
                datum.harmonizedCode3 = ''
                datum.harmonizedCode4 = ''
                datum.harmonizedCode5 = ''
            })
        }
        if (shipmentType !== 'BRASS') {
            getValues('goodsDetails').forEach((datum) => {
                datum.c4Code1 = ''
                datum.c4Code2 = ''
                datum.c4Code3 = ''
                datum.c4Code4 = ''
                datum.c4Code5 = ''
            })
        }
    }, [shipmentType])

    useEffect(() => {
        if (entryType === '61') {
            setValue('mexicanPedimento', '')
            setValue('foreignDestination', '')
            setValue('exitDate', null)

            getValues('goodsDetails').forEach((datum) => {
                datum.harmonizedCode1 = ''
                datum.harmonizedCode2 = ''
                datum.harmonizedCode3 = ''
                datum.harmonizedCode4 = ''
                datum.harmonizedCode5 = ''
            })
        }
        if (entryType === '70') {
            setValue('mexicanPedimento', '')
            setValue('exitDate', null)
        }
    }, [entryType])

    function getCurrentDateTime() {
        let date = new Date();
        return date.toISOString().split('.')[0].replace(/[^\d]/gi, '').slice(2);
    }


    return (
        <CngSection title='Account' helperText={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'shipmentHeaderSectionHelper')} alignItems='flex-end'>
            <Grid container spacing={1}>

                <CngGridItem xs={12}>
                    <Grid container spacing={1}>

                        <CngGridItem xs={12} md={6} lg={3} shouldHide={shouldHideMap.shipmentType}>
                            <CngCodeMasterAutocompleteField
                                required
                                name='shipmentType'
                                label={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'shipmentType')}
                                disabled={disabled || getValues('status') === '1000'}
                                codeType='ACE_HW_SHIPMENT_TYPE'
                                onChange={(value) => {
                                    setValue('shipmentType', value)
                                    setValue('entryType', '')
                                }}
                                size='small'
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} md={6} lg={3} shouldHide={shouldHideMap.carrierScac}>
                            <CngSelectField
                                required
                                name='carrierScac'
                                label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'carrierScac')}
                                disabled={disabled || getValues('status') === '1000'}
                                items={scacList}
                                onChange={(e) => {
                                    if (ccnAutoGenerated) {
                                        setValue('scn', e.target.value.toUpperCase() + getCurrentDateTime())
                                    } else {
                                        if (onChangeScacCode || editPage) {
                                            var removedPrefix = scn.substring(4);
                                            setValue('scn', e.target.value.toUpperCase() + removedPrefix)
                                        } else {
                                            setOnChangeScacCode(true);
                                            setValue('scn', e.target.value.toUpperCase() + scn)
                                        }
                                    }
                                }}
                                size='small'
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} md={6} lg={3} shouldHide={shouldHideMap.scn}>
                            <CngTextField
                                size='small'
                                required
                                name='scn'
                                label={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'scn')}
                                disabled={disabled || getValues('status') === '1000'}
                                onChange={(e) => {
                                    if (ccnAutoGenerated && scn.length == 3) {
                                        setValue('scn', e.target.value.toUpperCase() + getCurrentDateTime())
                                    } else {
                                        setValue('scn', e.target.value.toUpperCase())
                                    }
                                }}
                                onBlur={(e) => {
                                    setValue('scn', e.target.value.trim())
                                }}
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} md={6} lg={3} shouldHide={shouldHideMap.pointOfLoading}>
                            <NaAceHwyPOLPortAutocompleteField
                                size='small'
                                required
                                name='pointOfLoading'
                                label={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'pointOfLoading')}
                                disabled={disabled}
                            />
                        </CngGridItem>

                        {(!masterShipment) && (
                            <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.splitIndicator}>
                                <Switch
                                    disabled={disabled}
                                    fullWidth
                                    label={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'splitIndicator')}
                                    name='splitIndicator'
                                />
                            </CngGridItem>
                        )}

                        {(!splitShipment) && (
                            <>
                                <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.fdaIndicator}>
                                    <Switch disabled={disabled} fullWidth
                                        label={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'fdaIndicator')} name='fdaIndicator'
                                    />
                                </CngGridItem>
                                <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.serviceType}>
                                    <CngCodeMasterAutocompleteField
                                        size='small'
                                        name='serviceType'
                                        label={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'serviceType')}
                                        disabled={disabled}
                                        codeType='ACE_HW_SHIPMENT_SERVICE_TYPE'
                                    />
                                </CngGridItem>
                            </>
                        )}

                        {splitShipment && (
                            <>
                                <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap.quantity}>
                                    <CngTextField
                                        required
                                        name='quantity'
                                        label={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'quantity')}
                                        disabled={disabled}
                                        size='small'
                                    />
                                </CngGridItem>
                            </>
                        )}

                    </Grid>
                </CngGridItem>

                <CngGridItem xs={12}>
                    <Grid container spacing={1}>
                        {shipmentType && !splitShipment && (
                            <>
                                {(shipmentType === 'IN-BOND' || shipmentType === 'SEC 321') && (
                                    <>
                                        {shipmentType === 'IN-BOND' && (
                                            <>
                                                <CngGridItem xs={12} md={6} lg={4} shouldHide={shouldHideMap.entryType}>
                                                    <CngCodeMasterAutocompleteField
                                                        size='small'
                                                        required
                                                        name='entryType'
                                                        label={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'entryType')}
                                                        disabled={disabled || getValues('status') === '1000'}
                                                        codeType='ACE_HW_ENTRY_TYPE_IN_BOND'
                                                    />
                                                </CngGridItem>
                                                <CngGridItem xs={12} md={6} lg={4} shouldHide={shouldHideMap.inBondDestination}>
                                                    <NaAceHwyPOEPortAutocompleteField
                                                        size='small'
                                                        required
                                                        name='inBondDestination'
                                                        label={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'inBondDestination')}
                                                        disabled={disabled}
                                                    />
                                                </CngGridItem>
                                                <CngGridItem xs={12} md={6} lg={4} shouldHide={shouldHideMap.inBond7512No}>
                                                    <CngTextField
                                                        size='small'
                                                        required
                                                        name='inBond7512No'
                                                        label={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'inBond7512No')}
                                                        disabled={disabled}
                                                        inputProps={{ maxLength: 9 }}
                                                        onBlur={(e) => {
                                                            setValue('inBond7512No', e.target.value.trim())
                                                        }}
                                                    />
                                                </CngGridItem>
                                                <CngGridItem xs={12} md={6} lg={4} shouldHide={shouldHideMap.masterInBondNo}>
                                                    <CngTextField
                                                        size='small'
                                                        name='masterInBondNo'
                                                        label={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'masterInBondNo')}
                                                        disabled={disabled}
                                                        inputProps={{ maxLength: 12 }}
                                                        onBlur={(e) => {
                                                            setValue('masterInBondNo', e.target.value.toUpperCase().trim())
                                                        }}
                                                    />
                                                </CngGridItem>
                                                <CngGridItem xs={12} md={6} lg={4} shouldHide={shouldHideMap.firmsCode}>
                                                    <CngTextField
                                                        size='small'
                                                        name='firmsCode'
                                                        label={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'firmsCode')}
                                                        disabled={disabled}
                                                        onBlur={(e) => {
                                                            setValue('firmsCode', e.target.value.toUpperCase().trim())
                                                        }}
                                                    />
                                                </CngGridItem>
                                            </>
                                        )}

                                        {shipmentType === 'SEC 321' && (
                                            <CngGridItem xs={12} md={6} lg={4} shouldHide={shouldHideMap.entryType}>
                                                <CngCodeMasterAutocompleteField
                                                    size='small'
                                                    required
                                                    name='entryType'
                                                    label={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'entryType')}
                                                    disabled={disabled || getValues('status') === '1000'}
                                                    codeType='ACE_HW_ENTRY_TYPE_SEC'
                                                />
                                            </CngGridItem>
                                        )}

                                        <CngGridItem xs={12} md={6} lg={4} shouldHide={shouldHideMap.acquittalNo}>
                                            <CngTextField
                                                size='small'
                                                name='acquittalNo'
                                                label={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'acquittalNo')}
                                                disabled={disabled}
                                                onBlur={(e) => {
                                                    setValue('acquittalNo', e.target.value.toUpperCase().trim())
                                                }}
                                            />
                                        </CngGridItem>
                                    </>
                                )}

                                {((shipmentType === 'IN-BOND' && (entryType === '62' || entryType === '63')) || shipmentType === 'ASTRAY') && (
                                    <CngGridItem xs={12} md={6} lg={4} shouldHide={shouldHideMap.exitDate}>
                                        <CngDateField
                                            required={shipmentType === 'ASTRAY' || entryType === '62' || entryType === '63'}
                                            size='small'
                                            name='exitDate'
                                            label={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'exitDate')}
                                            disabled={disabled}
                                        />
                                    </CngGridItem>
                                )}

                                {shipmentType === 'IN-BOND' && entryType !== null && (entryType === '62' || entryType === '63') && (
                                    <>
                                        <CngGridItem xs={12} md={6} lg={4} shouldHide={shouldHideMap.mexicanPedimento}>
                                            <CngTextField
                                                size='small'
                                                name='mexicanPedimento'
                                                label={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'mexicanPedimento')}
                                                disabled={disabled}
                                                inputProps={{ maxLength: 15 }}
                                                onBlur={(e) => {
                                                    setValue('mexicanPedimento', e.target.value.toUpperCase().trim())
                                                }}
                                            />
                                        </CngGridItem>
                                        <CngGridItem xs={12} md={6} lg={4} shouldHide={shouldHideMap.foreignDestination}>
                                            <NaAceHwyPOLPortAutocompleteField
                                                size='small'
                                                required
                                                name='foreignDestination'
                                                label={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'foreignDestination')}
                                                disabled={disabled}
                                            />
                                        </CngGridItem>
                                    </>
                                )}
                                {shipmentType === 'IN-BOND' && entryType !== null && entryType === '70' && (
                                    <>
                                        <CngGridItem xs={12} md={6} lg={4} shouldHide={shouldHideMap.foreignDestination}>
                                            <NaAceHwyPOLPortAutocompleteField
                                                size='small'
                                                required
                                                name='foreignDestination'
                                                label={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'foreignDestination')}
                                                disabled={disabled}
                                            />
                                        </CngGridItem>
                                    </>
                                )}
                            </>
                        )}
                    </Grid>
                </CngGridItem>

            </Grid>
        </CngSection>
    )

}

export default ShipmentDetailsSection