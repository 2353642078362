import { Box, Card, CardContent } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { components, constants, DataFlattener, useTranslation } from 'cng-web-lib'
import ShipmentEditForm from './ShipmentEditPage'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import FormProperties from './FormProperties'
import pathMap from '../../../paths/pathMap'
import Namespace from '../../../constants/locale/Namespace'
import AceHighwayShipmentKeys from '../../../constants/locale/key/AceHighwayShipment'
import AceHighwayShipmentApiUrls from '../../../apiUrls/AceHighwayShipmentApiUrls'
import { FileForUserGetUserDetails, GetLoggedInUserPartyName } from 'src/common/FileForUserCommon'
import withFormState from '../../../components/aciacehighway/hocs/withFormState'
import { getStatusMetadata } from '../../../common/NACommon'
import Stepper from '../../../components/aciacehighway/Stepper'
import ViewLogsHistory from './ViewLogsHistory'
import ChargeTypeDialog from '../../common/ChargeTypeDialog'
import ErrorMessageDialog from 'src/views/common/ErrorMessageDialog'

const {
  table: { useFetchCodeMaintenanceLookup },
} = components

const { filter: { EQUAL } } = constants

function EditPage(props) {
  const { history, loading, location, onSetLoading, showNotification } = props
  const { state } = location
  const shipmentData = useRef(state?.shipmentData ? state.shipmentData : null)
  const [step, setStep] = useState((state?.step ? state.step : 1) - 1)
  const [shipmentStatus, setShipmentStatus] = useState(state?.status ? state.status : null)
  const [isMaster, setIsMaster] = useState(state?.isMaster ? state.isMaster : null)
  const [scn, setScn] = useState(state?.scn ? state.scn : null)
  const [stepIconColor, setStepIconColor] = useState({})
  const [completed, setCompleted] = useState({})
  const { id } = useParams()
  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_SHIPMENT)
  const translatedTextsObject = makeTranslatedTextsObject()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  const [chargeTypes, setChargeTypes] = useState([])
  const [submitDialog, setSubmitDialog] = useState({ open: false, items: [] })
  const [errorMessageDialog, setErrorMessageDialog] = useState({ open: false, errorMessages: [] })

  const [isSuperParty, setSuperParty] = useState(false);

  useEffect(() => {
    Promise.all([
      // Charge type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_CHARGE_TYPE' }],
        undefined,
        'code'
      ),
      // Super party
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_SUPER_PARTY' }],
        undefined, 'code'
      )
    ]).then(
      ([billingChargeTypes, billingSuperParty]) => {
        compileChargeTypes(billingChargeTypes)

        Object.keys(billingSuperParty).forEach(e => {
          if (e == GetLoggedInUserPartyName()) {
            setSuperParty(true);
          }
        })
      }
    )
  }, [])

  function compileChargeTypes(billingChargeTypes) {
    var jsonArray = [];
    if (billingChargeTypes) {
      Object.entries(billingChargeTypes).forEach((e) => {
        var jsonObject = { code: e[0], desc: e[1] }
        jsonArray.push(jsonObject)
      })
    }
    setChargeTypes(jsonArray)
  }

  // useEffect(() => {
  //   if (shipmentData != null && shipmentData != undefined) {
  //     if (shipmentData.current != null && shipmentData.current != undefined) {
  //       setShipmentStatus(shipmentData.current.status)
  //     }
  //   }
  // }, []);

  useEffect(() => {
    if (shipmentStatus) {
      if (shipmentStatus !== '1005') {
        setCompleted({ 0: true })
      }

      if (shipmentStatus === '1001') {
        const rejectedColor = getStatusMetadata(shipmentStatus)

        setStepIconColor({ 0: rejectedColor })
      }
    }
  }, [shipmentStatus])

  function makeTranslatedTextsObject() {
    let submitSuccessMsg = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.SUBMIT_SUCCESS_MESSAGE
    )

    let submitErrorMessage = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.SUBMIT_ERROR_MESSAGE
    )

    return { submitSuccessMsg, submitErrorMessage }
  }

  function preSubmit() {
    const values = { ...shipmentData.current, id }

    if (isSuperParty) {
      setSubmitDialog({ open: true, items: values })
    } else {
      values.chargeAs = 'REGULAR'
      shipmentData.current = values
      handleSubmitShipment()
    }
  }

  function handleSubmitShipment() {
    onSetLoading(true)
    
    const values = { ...shipmentData.current }

    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      values.fileForUserId = fileForUserDetails.fileForUserId;
      values.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      values.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }

    var serverData = FormProperties.toServerDataFormat(values)
    serverData.messageFunction = "23"
    var shipmentSubmitData = [serverData]
    const instance = axios.create({
      withCredentials: false,
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userToken'))
      }
    })

    instance
      .post(AceHighwayShipmentApiUrls.SUBMIT, shipmentSubmitData)
      .then(
        (response) => {
          const data = response.data
          var errorMessages = []
          var errorFlag = false
          for (var key in data) {
            var obj = data[key]
            if (obj.errorMessages != null && obj.errorMessages.length > 0) {
              errorMessages.push(obj.errorMessages)
              errorFlag = true
            }
          }
          if (errorFlag) {
            setErrorMessageDialog({ open: true, errorMessages })
          } else {
            showNotification('success', translatedTextsObject.submitSuccessMsg)
            history.push(pathMap.ACE_HIGHWAY_SHIPMENT_LIST_VIEW)
          }
        },
        error => {
          console.log(error)
          showNotification('error', translatedTextsObject.submitErrorMessage)
        }
      )
      .finally(() => {
        onSetLoading(false)
      })
  }

  function handleCancelShipment() {
    onSetLoading(true)
    const values = { ...shipmentData.current }

    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      values.fileForUserId = fileForUserDetails.fileForUserId;
      values.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      values.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }

    values.messageFunction = '22'
    values.messageIndicator = 'SINGLE'

    const instance = axios.create({
      withCredentials: false,
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userToken'))
      }
    })

    instance
      .post(AceHighwayShipmentApiUrls.CANCEL, DataFlattener.unflatten(FormProperties.toServerDataFormat(values)))
      .then(
        (result) => {
          var returnData = result.data
          var errorMessages = returnData.errorMessages
          if (
            result.status == 200 &&
            returnData != null &&
            errorMessages == null
          ) {
            showNotification('success', 'Shipment cancelled.')
            onSetLoading(false)
            history.push(pathMap.ACE_HIGHWAY_SHIPMENT_LIST_VIEW)
          } else {
            showNotification('error', errorMessages)
            onSetLoading(false)
          }
        },
        (error) => {
          console.log(error)
        }
      )
  }

  function renderContent(step) {
    switch (step) {
      case 0:
        return (
          <ShipmentEditForm
            showNotification={showNotification}
            onSetLoading={onSetLoading}
            onSubmitShipment={preSubmit}
            onCancelShipment={handleCancelShipment}
            shipmentData={shipmentData}
            id={id}
            isMaster={isMaster}
            setIsMaster={setIsMaster}
            setShipmentStatus={setShipmentStatus}
            setScn={setScn} />
        )
      case 1:
        return (
          <ViewLogsHistory
            scn={scn}
            history={history}
            onChangeStep={(step) => setStep(step)}
            step={step}
          />
        )
    }
  }

  return (
    <>
      <Card>
        <Stepper
          steps={'1005' !== shipmentStatus && isMaster ? ['Edit shipment', 'View logs & history'] : ['Create shipment'] }
          activeStep={step}
          completed={completed}
          onSetActiveStep={(step) => setStep(step)}
          stepIconColor={stepIconColor}
        />
        <Box padding={2}>
          {renderContent(step)}
        </Box>
      </Card>
      <ChargeTypeDialog
        chargeTypes={chargeTypes}
        onClose={() => setSubmitDialog({ open: false, items: [] })}
        onSubmit={(data) => {
          shipmentData.current = data
          handleSubmitShipment()
        }}
        open={submitDialog.open}
        items={submitDialog.items}
      />
      <ErrorMessageDialog
        errorMessages={errorMessageDialog.errorMessages}
        open={errorMessageDialog.open}
        onClose={() => setErrorMessageDialog({ open: false })} />
    </>
  )
}

export default withFormState(EditPage)
