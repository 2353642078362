import React, { useEffect, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'
import pathMap from '../../../paths/pathMap'
import MasterShipmentResponse from '../response/MasterShipmentResponse'
import { Box, Grid } from '@material-ui/core'
import CngSection from '../../../components/cngcomponents/CngSection'


const { button: { CngButton } } = components
const { filter: { EQUAL } } = constants

function ViewLogsHistory(props) {
  const { history, scn, onChangeStep, step } = props

  return (
    <>
      <CngSection title='Response log'>
        <MasterShipmentResponse scn={scn} />
      </CngSection>
      <Box marginTop={2}>
        <Grid container justify='space-between' spacing={3}>
          <Grid item xs={12} md='auto'>
            <CngButton
              color='secondary'
              onClick={() =>
                history.push(pathMap.ACE_HIGHWAY_SHIPMENT_LIST_VIEW)
              }
              size='medium'
            >
              Discard
            </CngButton>
          </Grid>
          <Grid item xs={12} md='auto'>
            <Grid container spacing={2}>
              <Grid item xs='auto'>
                <CngButton color='secondary' onClick={() => onChangeStep(step - 1)} size='medium'>
                  Back
                </CngButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default ViewLogsHistory
