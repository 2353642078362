import React, { useState, useContext } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { Grid } from '@material-ui/core'
import { ACEHighwayTranslationContext } from '../../contexts/ACEHighwayTranslationContext'
import CngSection from 'src/components/cngcomponents/CngSection'
import GoodsDetailsTable from '../GoodsDetailsTable'
import GoodsDetailsDialog from '../GoodsDetailsDialog'

function GoodsDetailsSection({ showNotification }) {
    const { getValues, setValue } = useFormContext()
    const { getTranslatedText } = useContext(ACEHighwayTranslationContext)

    const goodsDetails = useWatch({ name: 'goodsDetails' })
    const entryType = useWatch({ name: 'entryType' })
    const shipmentType = useWatch({ name: 'shipmentType' })
    const masterShipment = useWatch({ name: 'masterIndicator' })

    const [goodsDetailsDialog, setGoodsDetailsDialog] = useState({
        open: false,
        goodsDetails: null,
        isView: false
    })

    function handleDeleteGoodsDetails(data) {
        const goodsDetails = [...getValues('goodsDetails')].filter((goods) =>
            goods._id ? goods._id !== data._id : goods.id !== data.id
        )
        setValue('goodsDetails', goodsDetails)
    }


    return (
        <Grid item xs={12}>
            <CngSection title={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'goodsDetails')} helperText={getTranslatedText('ACE_HIGHWAY_SHIPMENT', 'goodsSectionHelper')} alignItems='flex-end'>
                <GoodsDetailsTable
                    goodsDetails={goodsDetails}
                    onAddGoodsDetails={() =>
                        setGoodsDetailsDialog({
                            open: true,
                            goodsDetails: null,
                            isView: false
                        })
                    }
                    onDeleteGoodsDetails={handleDeleteGoodsDetails}
                    onEditGoodsDetails={(goodsDetails) =>
                        setGoodsDetailsDialog({
                            open: true,
                            goodsDetails,
                            isView: false
                        })
                    }
                    onViewGoodsDetails={(goodsDetails) =>
                        setGoodsDetailsDialog({
                            open: true,
                            goodsDetails,
                            isView: true
                        })
                    }
                />
                <GoodsDetailsDialog
                    goodsDetails={goodsDetailsDialog.goodsDetails}
                    isView={goodsDetailsDialog.isView}
                    open={goodsDetailsDialog.open}
                    onClose={() =>
                        setGoodsDetailsDialog({
                            open: false,
                            goodsDetails: null,
                            isView: false
                        })
                    }
                    showNotification={showNotification}
                    shipmentType={shipmentType}
                    entryType={entryType}
                    masterShipment={masterShipment}
                />
            </CngSection>
        </Grid>
    )
}

export default GoodsDetailsSection