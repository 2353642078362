import React, { useState } from 'react'
import { useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import AceHighwayShipmentKeys from 'src/constants/locale/key/AceHighwayShipment'
import CngSection from '../../../components/cngcomponents/CngSection'
import CngSubSection from '../../../components/cngcomponents/CngSubSection'
import CngField from '../../../components/cngcomponents/CngField'
import TinyChip from '../../../components/aciacehighway/TinyChip'
import StatusBar from '../../../components/aciacehighway/StatusBar'
import GoodsDetailsTable from './GoodsDetailsTable'
import GoodsDetailsDialog from './GoodsDetailsDialog'
import moment from 'moment-timezone'
import { Box, Card, Grid, Typography, useTheme } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faCheckCircle } from '@fortawesome/pro-light-svg-icons'
import CryptoJS from 'crypto-js';

function ShipmentViewContent(props) {
  const { data, getLookupValue, getCountryStateLabel } = props
  const [goodsDetailsDialog, setGoodsDetailsDialog] = useState({
    open: false,
    goodsDetails: null
  })
  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_SHIPMENT)
  const translatedTextsObject = makeTranslatedTextsObject()
  const theme = useTheme()

  function makeTranslatedTextsObject() {
    let shipmentType = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.SHIPMENT_TYPE
    )
    let entryType = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.ENTRY_TYPE
    )
    let scn = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.SCN
    )
    let acquittalNo = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.ACQUITTAL_NO
    )
    let pointOfLoading = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.POINT_OF_LOADING
    )
    let fdaIndicator = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.FDA_INDICATOR
    )
    let serviceType = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.SERVICE_TYPE
    )
    let inBond7512No = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.IN_BOND7512_NO
    )
    let masterInBondNo = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.MASTER_IN_BOND_NO
    )
    let mexicanPedimento = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.MEXICAN_PEDIMENTO
    )
    let foreignDestination = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.FOREIGN_DESTINATION
    )
    let inBondDestination = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.IN_BOND_DESTINATION
    )
    let firmsCode = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.FIRMS_CODE
    )
    let exitDate = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.EXIT_DATE
    )
    let quantity = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.QUANTITY
    )
    let partyInfo = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.PartyInfo.TITLE
    )
    let partyType = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.PartyInfo.PARTY_TYPE
    )
    let partyIdentifier = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.PartyInfo.PARTY_IDENTIFIER
    )
    let name1 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.PartyInfo.NAME1
    )
    let name2 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.PartyInfo.NAME2
    )
    let address1 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.PartyInfo.ADDRESS1
    )
    let address2 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.PartyInfo.ADDRESS2
    )
    let address3 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.PartyInfo.ADDRESS3
    )
    let cityName = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.PartyInfo.CITY_NAME
    )
    let postalCode = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.PartyInfo.POSTAL_CODE
    )
    let country = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.PartyInfo.COUNTRY
    )
    let state = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.PartyInfo.STATE
    )
    let telNo = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.PartyInfo.TEL_NO
    )
    let email = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.PartyInfo.EMAIL
    )
    let ctpatInd = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.PartyInfo.CTPAT_IND
    )
    let partyDropDown = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.PartyInfo.PARTY_DROP_DOWN
    )
    let partyIdentifierCode = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.PartyInfo.PARTY_IDENTIFIER_CODE
    )
    let partyIdentifierValue = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.PartyInfo.PARTY_IDENTIFIER_VALUE
    )
    let partyIdentifierValueNote = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.PartyInfo.PARTY_IDENTIFIER_VALUE_NOTE
    )
    let partyIdentifierValueEIN = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.PartyInfo.PARTY_IDENTIFIER_VALUE_EIN
    )
    let partyIdentifierValueSSN = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.PartyInfo.PARTY_IDENTIFIER_VALUE_SSN
    )
    let partyIdentifierValueCAN = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.PartyInfo.PARTY_IDENTIFIER_VALUE_CAN
    )
    let portCode = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.PartyInfo.PORT_CODE
    )
    let routingCode = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.PartyInfo.ROUTING_CODE
    )
    let brokerDownloadRefNo = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.PartyInfo.BROKER_DOWNLOAD_REF_NO
    )
    let portOfLoading = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.PartyInfo.PORT_OF_LOADING
    )
    let filerCode = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.PartyInfo.FILER_CODE
    )
    let saveFlag = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.PartyInfo.SAVE_FLAG
    )
    let goodsDetails = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.TITLE
    )
    let editButton = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayShipmentKeys.EDIT_BUTTON
    )
    let cloneButton = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayShipmentKeys.CLONE_BUTTON
    )
    let deleteButton = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayShipmentKeys.DELETE_BUTTON
    )

    let phoneNo = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.PartyInfo.PHONE_NO
    )

    let faxNo = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.PartyInfo.FAX_NO
    )

    return {
      shipmentType,
      entryType,
      scn,
      acquittalNo,
      pointOfLoading,
      fdaIndicator,
      serviceType,
      inBond7512No,
      masterInBondNo,
      mexicanPedimento,
      foreignDestination,
      inBondDestination,
      firmsCode,
      exitDate,
      quantity,
      partyInfo,
      partyType,
      partyIdentifier,
      name1,
      name2,
      address1,
      address2,
      address3,
      cityName,
      postalCode,
      country,
      state,
      telNo,
      email,
      ctpatInd,
      partyDropDown,
      partyIdentifierCode,
      partyIdentifierValue,
      partyIdentifierValueNote,
      partyIdentifierValueEIN,
      partyIdentifierValueSSN,
      partyIdentifierValueCAN,
      portCode,
      routingCode,
      brokerDownloadRefNo,
      portOfLoading,
      filerCode,
      saveFlag,
      goodsDetails,
      editButton,
      cloneButton,
      deleteButton,
      phoneNo,
      faxNo
    }
  }

  const { shipmentType, entryType } = data

  const isFdaRegulated =
    data.fdaIndicator === 'Y' ? true : data.fdaIndicator === 'N' && false

  const isCtpatMember =
    data.ctpatInd === 'Y' ? true : data.ctpatInd === 'N' && false

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <StatusBar status={data.status} />
        </Grid>
        <Grid item xs={12}>
          <Grid container justify='space-between' spacing={3}>
            <Grid item xs={12} md='auto'>
              <Typography variant='body2'>
                The following shipment information was updated on{' '}
                <strong>
                  {moment(data.updatedDate).tz("Canada/Eastern").format('dddd, D MMM YYYY, HH:mm')}
                </strong>
              </Typography>
            </Grid>
            <Grid item xs={12} md='auto'>
              <Typography variant='body2' style={{ fontWeight: 700 }}>
                {`${translatedTextsObject.acquittalNo}: ${data.acquittalNo || '-'}`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <CngSection title='Account'>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <CngField label='Account type'>{shipmentType}</CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CngField label={translatedTextsObject.entryType}>
                  {getLookupValue('entryType', entryType)}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CngField>
                  <Box display='flex' alignItems='center' style={{ gap: 8 }}>
                    <FontAwesomeIcon
                      color={
                        isFdaRegulated
                          ? theme.palette.primary.main
                          : theme.palette.error.main
                      }
                      icon={isFdaRegulated ? faCheckCircle : faBan}
                    />
                    <Typography variant='body2'>
                      {isFdaRegulated ? 'FDA regulated' : 'Not FDA regulated'}
                    </Typography>
                  </Box>
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CngField label={translatedTextsObject.scn}>
                  {data.scn}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CngField label={translatedTextsObject.pointOfLoading}>
                  <Box display='flex' alignItems='center' style={{ gap: 8 }}>
                    <Typography variant='body2'>
                      {getLookupValue('pointOfLoading', data.pointOfLoading)}
                    </Typography>
                    <TinyChip label={data.pointOfLoading} variant='outlined' />
                  </Box>
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CngField label={translatedTextsObject.serviceType}>
                  {data.serviceType}
                </CngField>
              </Grid>
              {data.splitIndicator && (
                <Grid item xs={12} sm={6} md={4}>
                  <CngField label={translatedTextsObject.quantity}>
                    {data.quantity}
                  </CngField>
                </Grid>
              )}
              {shipmentType && (
                <>
                  {shipmentType === 'IN-BOND' && (
                    <>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField
                          label={translatedTextsObject.inBondDestination}
                        >
                          {data.inBondDestination}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.inBond7512No}>
                          {data.inBond7512No}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.masterInBondNo}>
                          {data.masterInBondNo}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.firmsCode}>
                          {data.firmsCode}
                        </CngField>
                      </Grid>
                    </>
                  )}
                  {(shipmentType === 'IN-BOND' ||
                    shipmentType === 'SEC 321') && (
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.acquittalNo}>
                          {data.acquittalNo}
                        </CngField>
                      </Grid>
                    )}
                  {(shipmentType === 'IN-BOND' ||
                    shipmentType === 'ASTRAY') && (
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.exitDate}>
                          {data.exitDate &&
                            moment(data.exitDate).tz("Canada/Eastern").format('D MMM YYYY')}
                        </CngField>
                      </Grid>
                    )}
                  {shipmentType === 'IN-BOND' &&
                    entryType !== null &&
                    (entryType === '62' || entryType === '63') && (
                      <>
                        <Grid item xs={12} sm={6} md={4}>
                          <CngField
                            label={translatedTextsObject.mexicanPedimento}
                          >
                            {data.mexicanPedimento}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <CngField
                            label={translatedTextsObject.foreignDestination}
                          >
                            {data.foreignDestination}
                          </CngField>
                        </Grid>
                      </>
                    )}
                </>
              )}
            </Grid>
          </CngSection>
        </Grid>
        <Grid item xs={12}>
          <CngSection title={translatedTextsObject.partyInfo}>
            {data.partyInfo.length > 0 ? (
              <Grid container spacing={2}>
                {data.partyInfo.map((party) => {
                  var encryptedBase64Key = "Y3JpbXNvbmxvZ2ljMTIzNA==";
                  var parsedBase64Key = CryptoJS.enc.Base64.parse(encryptedBase64Key);

                  let enableEncryption = `${process.env.REACT_APP_NA_SHOULD_ENABLE_ENCRYPTION}`
                  if (enableEncryption == "true" && party.partyIdentifierValue && party.partyIdentifierValue.includes("=")) {
                    var decryptedData = CryptoJS.AES.decrypt(party.partyIdentifierValue, parsedBase64Key, {
                      mode: CryptoJS.mode.ECB,
                      padding: CryptoJS.pad.Pkcs7
                    });

                    var decryptedText = decryptedData.toString(CryptoJS.enc.Utf8);

                    party.partyIdentifierValue = decryptedText;
                  }

                  const { partyType } = party

                  return (
                    <Grid key={party.id || party._id} item xs={12}>
                      <Card variant='outlined' style={{ backgroundColor: theme.palette.grey[50] }}>
                        <Box padding={2}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                              <CngField label={getLookupValue('partyType', party.partyType)}>
                                {[party.name1, party.name2]
                                  .filter((name) => name)
                                  .join(' ')}
                              </CngField>
                            </Grid>
                            <Grid item xs={12} md={8}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                  <CngField label={translatedTextsObject.partyIdentifier}>
                                    {party.partyIdentifier}
                                  </CngField>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <CngField label='Address'>
                                    {[party.address1, party.address2, party.address3]
                                      .filter((address) => address)
                                      .join(', ')}
                                  </CngField>
                                </Grid>
                                {party.partyType === "CB" && <>
                                  <Grid item xs={12} md={6}>
                                    <CngField label={translatedTextsObject.phoneNo}>
                                      {party.phoneNo}
                                    </CngField>
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <CngField label={translatedTextsObject.faxNo}>
                                      {party.faxNo}
                                    </CngField>
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <CngField label={translatedTextsObject.email}>
                                      {party.email}
                                    </CngField>
                                  </Grid>
                                </>}
                                <Grid item xs={12} md={6}>
                                  <CngField label='Address'>
                                    {[party.address1, party.address2, party.address3]
                                      .filter((address) => address)
                                      .join(', ')}
                                  </CngField>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <CngField label={translatedTextsObject.cityName}>
                                    {party.cityName}
                                  </CngField>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <CngField label={translatedTextsObject.postalCode}>
                                    {party.postalCode}
                                  </CngField>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <CngField label={translatedTextsObject.state}>
                                    {getCountryStateLabel(party.country, party.state)}
                                  </CngField>
                                </Grid>
                                {partyType !== null && (
                                  <>
                                    {(partyType === 'NI' ||
                                      partyType === 'IM' ||
                                      partyType === 'GC') && (
                                        <Grid item xs={12} md={6}>
                                          <CngField label={translatedTextsObject.partyIdentifierCode}>
                                            {party.partyIdentifierCode}
                                          </CngField>
                                        </Grid>
                                      )}
                                    {(partyType === 'CB' ||
                                      partyType === 'NI' ||
                                      partyType === 'GC' ||
                                      partyType === 'IM') && (
                                        <Grid item xs={12} md={6}>
                                          <CngField
                                            label={
                                              partyType === 'CB'
                                                ? translatedTextsObject.filerCode
                                                : translatedTextsObject.partyIdentifierValue
                                            }
                                          >
                                            {party.partyIdentifierValue}
                                          </CngField>
                                        </Grid>
                                      )}
                                    {partyType === 'CB' && (
                                      <>
                                        <Grid item xs={12} md={6}>
                                          <CngField label={translatedTextsObject.portCode}>
                                            {party.portCode}
                                          </CngField>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                          <CngField label={translatedTextsObject.routingCode}>
                                            {party.routingCode}
                                          </CngField>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                          <CngField label={translatedTextsObject.brokerDownloadRefNo}>
                                            {party.brokerDownloadRefNo}
                                          </CngField>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                          <CngField label={translatedTextsObject.portOfLoading}>
                                            {party.portOfLoading}
                                          </CngField>
                                        </Grid>
                                      </>
                                    )}
                                  </>
                                )}
                              </Grid>
                            </Grid>
                            {partyType !== null &&
                              (partyType === '' ||
                                partyType === 'OS' ||
                                partyType === 'CN') && (
                                <Grid item xs={12}>
                                  <CngSection title='Other Details'>
                                    <Grid container spacing={2}>
                                      <Grid item xs={12} md={4}>
                                        <Box
                                          display='flex'
                                          alignItems='center'
                                          style={{ gap: 8 }}
                                        >
                                          <FontAwesomeIcon
                                            color={
                                              isCtpatMember
                                                ? theme.palette.primary.main
                                                : theme.palette.error.main
                                            }
                                            icon={isCtpatMember ? faCheckCircle : faBan}
                                          />
                                          <Typography variant='body2'>
                                            CTPAT member
                                          </Typography>
                                        </Box>
                                      </Grid>
                                      <Grid item xs={12} md={4}>
                                        <CngField label={translatedTextsObject.telNo}>
                                          {party.telNo}
                                        </CngField>
                                      </Grid>
                                      <Grid item xs={12} md={4}>
                                        <CngField label={translatedTextsObject.email}>
                                          {party.email}
                                        </CngField>
                                      </Grid>
                                    </Grid>
                                  </CngSection>
                                </Grid>
                              )}
                          </Grid>
                        </Box>
                      </Card>
                    </Grid>
                  )
                })}
              </Grid>
            ) : (
              <Typography variant='body2' color='textSecondary'>
                No records to display
              </Typography>
            )}
          </CngSection>
        </Grid>
        <Grid item xs={12}>
          <CngSection title={translatedTextsObject.goodsDetails}>
            <GoodsDetailsTable
              goodsDetails={data.goodsDetails}
              onViewGoodsDetails={(goodsDetails) =>
                setGoodsDetailsDialog({ open: true, goodsDetails })
              }
            />
          </CngSection>
        </Grid>
      </Grid>
      <GoodsDetailsDialog
        goodsDetails={goodsDetailsDialog.goodsDetails}
        isView
        onClose={() =>
          setGoodsDetailsDialog({ open: false, goodsDetails: null })
        }
        open={goodsDetailsDialog.open}
        shipmentType={shipmentType}
        entryType={entryType}
      />
    </>
  )
}

export default ShipmentViewContent
