import React, { useState,useEffect } from 'react'
import { components, useServices, useTranslation } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import AceHighwayShipmentKeys from '../../../../constants/locale/key/AceHighwayShipment'
import AceHighwayShipmentApiUrls from '../../../../apiUrls/AceHighwayShipmentApiUrls'
import { Box, Grid, Paper } from '@material-ui/core'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import ErrorMessageSection from 'src/views/common/ErrorMessageSection'

const {
  button: { CngButton },
  dropzone: { CngFileUpload },
  CngDialog
} = components

function ShipmentXMLUploadDialog(props) {
  const { onClose, open, showNotification } = props

  const [files, setFiles] = useState([])
  const [errorMessages, setErrorMessages] = useState([])
  const [loading, setLoading] = useState(false)
  const { securedSendRequest } = useServices()
  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_SHIPMENT)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let saveButton = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.SAVE_BUTTON
    )

    let dialogTitle = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.UPLOAD_XML_DILAOG_TITLE
    )

    let successMsg = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.UPLOAD_XML_SUCCESS_MSG
    )

    let errorMsg = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.UPLOAD_XML_ERROR_MSG
    )

    let fileEmptyMsg = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.UPLOAD_XML_FILE_EMPTY_ERROR_MSG
    )

    let discardButton = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.DISCARD_BUTTON
    )

    return { 
      saveButton,
      dialogTitle, 
      successMsg, 
      errorMsg, 
      discardButton,
      fileEmptyMsg
    }
  }

  useEffect(() => {
    setFiles([])
    setErrorMessages([])
  },[open]);

  function handleUploadShipment() {

    if(files.length>0){
      try {
        setLoading(true)

        let fileForUserDetails = FileForUserGetUserDetails();
        const formData = new FormData()

        formData.append('file', files[0].file)
        if (fileForUserDetails != null && fileForUserDetails != undefined) {
          formData.append("fileForUserDetails", JSON.stringify(fileForUserDetails))
        } else {
          formData.append("fileForUserDetails", "{}")
        }

        securedSendRequest.execute(
          'POST',
          AceHighwayShipmentApiUrls.UPLOAD_XML,
          formData,
          (response) => {
            if (response.status === 200) {
              if (response.data) {
                const errorMessages = response.data.errorMessages

                if (errorMessages) {
                  setErrorMessages(errorMessages)
                  setFiles([])
                }
              }else{
                showNotification(
                  'success',
                  translatedTextsObject.successMsg
                )
                onClose()
                open.tableRef.current.performRefresh()
              }
            }
          },
          () => {
            showNotification('error', translatedTextsObject.errorMsg)
            setFiles([])
          },
          () => setLoading(false)
        )
      } catch (error) {
        console.log(error)
      }
    }else{
      showNotification('error', translatedTextsObject.fileEmptyMsg)
    }
  }

  return (
    <CngDialog
      dialogTitle={translatedTextsObject.dialogTitle}
      dialogContent={
        <Paper variant='outlined'>
          <Box padding={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CngFileUpload
                  accept={['.xml']}
                  files={files}
                  maxFiles={1}
                  maxSize={5.3e+7}
                  onFileSelect={(file) => setFiles(file)}
                  showFormFields={false}
                  moreActions={[
                    {
                      action: 'remove',
                      name: 'Remove',
                      icon: ['fal', 'trash']
                    }
                  ]}
                  onDropRejected={(errorMessages) => console.log(errorMessages)}
                />
              </Grid>
              <ErrorMessageSection errorMessages={errorMessages} />
            </Grid>
          </Box>
        </Paper>
      }
      dialogAction={
        <>
          <CngButton color='secondary' disabled={loading} onClick={onClose}>
            {translatedTextsObject.discardButton}
          </CngButton>
          <CngButton
            color='primary'
            disabled={loading}
            onClick={handleUploadShipment}
            shouldShowProgress={loading}
          >
            {translatedTextsObject.saveButton}
          </CngButton>
        </>
      }
      fullWidth
      maxWidth='lg'
      open={open.open}
      onClose={onClose}
      shouldShowCloseButton
    />
  )
}

export default ShipmentXMLUploadDialog
