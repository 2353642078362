import React, { useState } from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import AceHighwayShipmentKeys from 'src/constants/locale/key/AceHighwayShipment'
import TinyChip from 'src/components/aciacehighway/TinyChip'
import {
  Box,
  Grid,
  InputAdornment,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'

const {
  button: { CngButton, CngIconButton }
} = components

const useStyles = makeStyles((theme) => ({
  table: {
    '& .MuiTableHead-root': {
      backgroundColor: theme.palette.background.sectionOddBg,
      padding: '12px 16px',
      '& .MuiTableCell-head': {
        borderBottom: 0,
        fontSize: 12,
        fontWeight: 700,
        lineHeight: 1.2,
        textTransform: 'uppercase'
      }
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        cursor: 'pointer',
        '&:last-child': {
          '& .MuiTableCell-root': {
            borderBottom: 0
          }
        }
      }
    },
    '& .MuiTableCell-root': {
      padding: '8px 16px'
    }
  }
}))

function GoodsDetailsTable(props) {
  const {
    goodsDetails,
    onViewGoodsDetails,
    onAddGoodsDetails,
    onDeleteGoodsDetails,
    onEditGoodsDetails
  } = props

  const [searchTerm, setSearchTerm] = useState('')
  const classes = useStyles()
  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_SHIPMENT)
  const translatedTextsObject = makeTranslatedTextsObject()

  const filtered =
    searchTerm !== ''
      ? goodsDetails.filter((goods) =>
          goods.goodsDescription.includes(searchTerm)
        )
      : goodsDetails

  function makeTranslatedTextsObject() {
    let goodsDescription = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.GOODS_DESCRIPTION
    )
    let noOfPackages = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.NO_OF_PACKAGES
    )
    let noOfPackagesUom = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.NO_OF_PACKAGES_UOM
    )
    let grossWeight = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.GROSS_WEIGHT
    )
    let grossWeightUom = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.GROSS_WEIGHT_UOM
    )

    return {
      goodsDescription,
      noOfPackages,
      noOfPackagesUom,
      grossWeight,
      grossWeightUom
    }
  }

  return (
    <>
      <Box marginBottom={1}>
        <Grid container spacing={2}>
          <Grid item xs>
            {goodsDetails && goodsDetails.length > 0 && (
              <TextField
                fullWidth
                onChange={(event) => setSearchTerm(event.target.value)}
                placeholder='Search in your entries'
                size='small'
                variant='outlined'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <FontAwesomeIcon icon={['fal', 'search']} />
                    </InputAdornment>
                  )
                }}
              />
            )}
          </Grid>
          {onAddGoodsDetails && (
            <Grid item xs='auto'>
              <CngButton
                color='secondary'
                onClick={onAddGoodsDetails}
                size='medium'
                startIcon={<FontAwesomeIcon icon={['fal', 'plus-circle']} />}
              >
                Add commodity
              </CngButton>
            </Grid>
          )}
        </Grid>
      </Box>
      <TableContainer>
        <Table className={clsx(classes.table, 'new-ui-table-component__table')}>
          <TableHead>
            <TableRow>
              <TableCell>{translatedTextsObject.goodsDescription}</TableCell>
              <TableCell>
                {`${translatedTextsObject.noOfPackages} / ${translatedTextsObject.noOfPackagesUom}`}
              </TableCell>
              <TableCell>
                {`${translatedTextsObject.grossWeight} / ${translatedTextsObject.grossWeightUom}`}
              </TableCell>
              {(onEditGoodsDetails || onDeleteGoodsDetails) && (
                <TableCell align='right'>Action</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {filtered && filtered.length > 0 ? (
              filtered.map((goodsDetails) => (
                <TableRow
                  key={goodsDetails._id || goodsDetails.id}
                  hover
                  onClick={() => {
                    if (onViewGoodsDetails) {
                      onViewGoodsDetails(goodsDetails)
                    }
                  }}
                >
                  <TableCell>{goodsDetails.goodsDescription}</TableCell>
                  <TableCell>
                    <Typography component='div' variant='inherit'>
                      {goodsDetails.noOfPackages}
                      <TinyChip label={goodsDetails.noOfPackagesUom} variant='outlined' />
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography component='div' variant='inherit'>
                      {goodsDetails.grossWeight}
                      <TinyChip label={goodsDetails.grossWeightUom} variant='outlined' />
                    </Typography>
                  </TableCell>
                  {(onEditGoodsDetails || onDeleteGoodsDetails) && (
                    <TableCell>
                      <Grid container spacing={1} justify='flex-end'>
                        {onEditGoodsDetails && (
                          <Grid item xs='auto'>
                            <CngIconButton
                              type='outlined'
                              icon={['fal', 'pen']}
                              onClick={(event) => {
                                event.stopPropagation()
                                onEditGoodsDetails(goodsDetails)
                              }}
                              size='small'
                            />
                          </Grid>
                        )}
                        {onDeleteGoodsDetails && (
                          <Grid item xs='auto'>
                            <CngIconButton
                              type='outlined'
                              icon={['fal', 'trash']}
                              onClick={(event) => {
                                event.stopPropagation()
                                onDeleteGoodsDetails(goodsDetails)
                              }}
                              size='small'
                            />
                          </Grid>
                        )}
                      </Grid>
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  align='center'
                  colSpan={
                    3 + (onEditGoodsDetails || onDeleteGoodsDetails ? 1 : 0)
                  }
                >
                  <Typography variant='body2' color='textSecondary'>
                    No records to display
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default GoodsDetailsTable
