import AceHighwayShipmentApiUrls from 'src/apiUrls/AceHighwayShipmentApiUrls'
import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { components } from 'cng-web-lib'
import { useHistory, useParams } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'
import ConfirmDialog from '../../common/ConfirmDialog'
import pathMap from 'src/paths/pathMap'

const {
  button: { CngButton },
  form: { CngEditForm, CngAddFormButtonSection }
} = components

function EditPage({ showNotification, onSetLoading, onSubmitShipment, onCancelShipment, shipmentData, isMaster, setIsMaster, setShipmentStatus, setScn }) {
  const { id } = useParams()
  const onSuccessCallback = useRef(() => {})

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngEditForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          showNotification={showNotification}
          renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
            />
          )}
          formikProps={FormProperties.formikProps}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          fetch={{
            url: AceHighwayShipmentApiUrls.GET,
            onPreSuccess: (serverData) => {
              shipmentData.current = serverData
              setIsMaster(serverData.masterIndicator)
              setShipmentStatus(serverData.status)
              setScn(serverData.scn)
            }
          }}
          update={{
            url: AceHighwayShipmentApiUrls.PUT,
            onPostSubmitSuccess: (e) => {
              shipmentData.current = e
            }
          }}
          id={id}
          // renderButtonSection={() => {
          //   return (
          //     <CngAddFormButtonSection primaryButtonLabel="Update">
          //     </CngAddFormButtonSection>
          //   )
          // }}
          renderButtonSection={(_, loading) => (
            <AceHWShipmentCustomButtonSection
              onSaveOnly={() => {
                onSuccessCallback.current = () => {
                  onSetLoading(false)
                }
              }}
              loading={loading}
              onSetLoading={onSetLoading}
              onSubmitShipment={(data) => {
                shipmentData.current = data
                onSubmitShipment()
              }}
              onCancelShipment={() => {
                onCancelShipment()
              }}
              isMaster={isMaster}
            />
          )}
        />
      </Grid>
    </Grid>
  )
}

function AceHWShipmentCustomButtonSection(props) {
  const {
    loading,
    onSaveOnly,
    onSubmitShipment,
    onCancelShipment,
    onSetLoading,
    isMaster
  } = props

  const [confirmDialog, setConfirmDialog] = useState(false)
  const history = useHistory()
  const { getValues } = useFormContext()

  useEffect(() => {
    onSetLoading(loading)
  }, [loading])

  return (
    <>
      <Grid container justify='space-between' spacing={3}>
        <Grid item xs='auto'>
          <CngButton
            color='secondary'
            disabled={loading}
            onClick={() => setConfirmDialog(true)}
            size='medium'
          >
            Discard
          </CngButton>
        </Grid>
        <Grid item xs='auto'>
          <Grid container spacing={2}>
            <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={loading}
                onClick={onSaveOnly}
                type='submit'
                size='medium'
                shouldShowProgress={loading}
              >
                Update
              </CngButton>
            </Grid>
            {isMaster && (
                <Grid item xs='auto'>
                  <CngButton
                    color='primary'
                    disabled={loading}
                    onClick={() => onSubmitShipment(getValues())}
                    // type='submit'
                    variant='contained'
                    size='medium'
                    shouldShowProgress={loading}
                  >
                  Send Unassociated Shipment
                  </CngButton>
                </Grid>
            )}
            {isMaster && (
                <Grid item xs='auto'>
                  <CngButton
                    color='primary'
                    disabled={loading}
                    onClick={() => onCancelShipment(getValues())}
                    // type='submit'
                    size='medium'
                    shouldShowProgress={loading}
                  >
                    Cancel Shipment Message
                  </CngButton>
                </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialog}
        closeDialog={() => setConfirmDialog(false)}
        confirmDialog={() =>
          history.push(pathMap.ACE_HIGHWAY_SHIPMENT_LIST_VIEW)
        }
        content="All progress in this session will be lost and cant't be restored. Are you sure about this?"
        okMsg='Yes, discard'
        cancelMsg='Continue editing'
        title='Discard'
      />
    </>
  )
}

export default EditPage
