import AceHighwayShipmentApiUrls from 'src/apiUrls/AceHighwayShipmentApiUrls'
import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React, { useEffect } from 'react'
import { components } from 'cng-web-lib'
import pathMap from 'src/paths/pathMap'
import { useLocation } from "react-router-dom";

const {
  button: { CngButton },
  form: { CngAddForm }
} = components

function AddPage({ history, showNotification, onSetLoading }) {

  const loc = useLocation()
  let formikPropsData;
  let  manifestId;
  if(loc.state !== undefined) {
    formikPropsData = {
          ...FormProperties.formikProps,
          initialValues: FormProperties.toClientDataFormat(loc.state)     
    }
    manifestId=loc.state.manifestId;
  } else {
    formikPropsData = FormProperties.formikProps
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngAddForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          history={history}
          showNotification={showNotification}
          renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
            />
          )}
          formikProps={formikPropsData}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          create={{
            url: AceHighwayShipmentApiUrls.POST,
            onSuccess: () => {
              showNotification('success', 'Created successfully.')
              if(manifestId != '' && manifestId != undefined){
                history.push({pathname: `${pathMap.ACE_HIGHWAY_MANIFEST}/edit/`+manifestId, activeTabIndex : 1})
              }else{
                history.push({pathname: pathMap.ACE_HIGHWAY_SHIPMENT_LIST_VIEW})
              }
            }
          }}
          renderButtonSection={(loading) => (
            <CustomButtonSection
              history={history}
              loading={loading}
              onSetLoading={onSetLoading}
            />
          )}
        />
      </Grid>
    </Grid>
  )
}

export default AddPage

function CustomButtonSection(props) {
  const { history, loading, onSetLoading } = props

  useEffect(() => {
    onSetLoading(loading)
  }, [loading])

  return (
    <Grid container justify='space-between' spacing={3}>
      <Grid item xs='auto'>
        <CngButton
          color='secondary'
          disabled={loading}
          onClick={() => history.push(pathMap.ACE_HIGHWAY_SHIPMENT_LIST_VIEW)}
          size='medium'
        >
          Discard
        </CngButton>
      </Grid>
      <Grid item xs='auto'>
        <CngButton
          color='primary'
          disabled={loading}
          type='submit'
          size='medium'
          shouldShowProgress={loading}
        >
          Save
        </CngButton>
      </Grid>
    </Grid>
  )
}
