import React, { useRef } from 'react'
import { components, constants, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import AceHighwayManifestKeys from 'src/constants/locale/key/AceHighwayManifest'
import AceHighwayShipmentKeys from 'src/constants/locale/key/AceHighwayShipment'
import GoodsDetailsFormProperties from './GoodsDetailsFormProperties'
import CngSection from '../../../components/cngcomponents/CngSection'
import CngSubSection from 'src/components/cngcomponents/CngSubSection'
import CollapsibleSection from 'src/components/ui/CollapsibleSection'
import CngField from '../../../components/cngcomponents/CngField'
import TinyChip from '../../../components/aciacehighway/TinyChip'
import { useFormContext } from 'react-hook-form'
import { Box, Divider, Grid, Typography } from '@material-ui/core'

const {
  button: { CngButton, CngPrimaryButton },
  form: { CngForm },
  CngDialog
} = components

const { FormState } = constants

function GoodsDetailsDialog(props) {
  const {
    goodsDetails,
    isView,
    onClose,
    open,
    showNotification,
    shipmentType,
    entryType,
    masterShipment
  } = props

  const { initialValues, makeValidationSchema } =
    GoodsDetailsFormProperties.formikProps

  const lastIndex = useRef(isView ? null : 0)
  const formContext = useFormContext()
  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_SHIPMENT)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let goodsDetails = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.TITLE
    )
    let goodsDescription = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.GOODS_DESCRIPTION
    )
    let noOfPackages = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.NO_OF_PACKAGES
    )
    let noOfPackagesUom = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.NO_OF_PACKAGES_UOM
    )
    let grossWeight = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.GROSS_WEIGHT
    )
    let grossWeightUom = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.GROSS_WEIGHT_UOM
    )
    let monetaryAmount = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.MONETARY_AMOUNT
    )
    let countryOfOrigin = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.COUNTRY_OF_ORIGIN
    )
    let harmonizedCodeTitle = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.HARMONIZED_CODE_TITLE
    )
    let harmonizedCode1 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.HARMONIZED_CODE1
    )
    let harmonizedCode2 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.HARMONIZED_CODE2
    )
    let harmonizedCode3 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.HARMONIZED_CODE3
    )
    let harmonizedCode4 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.HARMONIZED_CODE4
    )
    let harmonizedCode5 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.HARMONIZED_CODE5
    )
    let c4CodeTitle = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.C4_CODE_TITLE
    )
    let c4Code1 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.C4_CODE1
    )
    let c4Code2 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.C4_CODE2
    )
    let c4Code3 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.C4_CODE3
    )
    let c4Code4 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.C4_CODE4
    )
    let c4Code5 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.C4_CODE5
    )
    let marksNoTitle = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.MARKS_NO_TITLE
    )
    let marksNo1 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.MARKS_NO1
    )
    let marksNo2 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.MARKS_NO2
    )
    let marksNo3 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.MARKS_NO3
    )
    let marksNo4 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.MARKS_NO4
    )
    let marksNo5 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.MARKS_NO5
    )
    let vinTitle = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.VIN_TITLE
    )
    let vin1 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.VIN1
    )
    let vin2 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.VIN2
    )
    let vin3 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.VIN3
    )
    let vin4 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.VIN4
    )
    let vin5 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.VIN5
    )
    let vin6 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.VIN6
    )
    let vin7 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.VIN7
    )
    let vin8 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.VIN8
    )
    let vin9 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.VIN9
    )
    let vin10 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.VIN10
    )
    let vin11 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.VIN11
    )
    let vin12 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.VIN12
    )
    let vin13 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.VIN13
    )
    let vin14 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.VIN14
    )
    let vin15 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.VIN15
    )
    let vin16 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.VIN16
    )
    let vin17 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.VIN17
    )
    let vin18 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.VIN18
    )
    let vin19 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.VIN19
    )
    let vin20 = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.GoodsDetails.VIN20
    )
    let dangerousGoods = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.DangerousGoods.TITLE
    )
    let undgCode = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.DangerousGoods.UNDG_CODE
    )
    let undgContactName = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.DangerousGoods.UNDG_CONTACT_NAME
    )
    let undgContactNo = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.DangerousGoods.UNDG_CONTACT_NO
    )
    let saveButton = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.SAVE_BUTTON
    )
    let updateButton = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.UPDATE_BUTTON
    )

    return {
      goodsDetails,
      goodsDescription,
      noOfPackages,
      noOfPackagesUom,
      grossWeight,
      grossWeightUom,
      monetaryAmount,
      countryOfOrigin,
      harmonizedCodeTitle,
      harmonizedCode1,
      harmonizedCode2,
      harmonizedCode3,
      harmonizedCode4,
      harmonizedCode5,
      c4CodeTitle,
      c4Code1,
      c4Code2,
      c4Code3,
      c4Code4,
      c4Code5,
      marksNoTitle,
      marksNo1,
      marksNo2,
      marksNo3,
      marksNo4,
      marksNo5,
      vinTitle,
      vin1,
      vin2,
      vin3,
      vin4,
      vin5,
      vin6,
      vin7,
      vin8,
      vin9,
      vin10,
      vin11,
      vin12,
      vin13,
      vin14,
      vin15,
      vin16,
      vin17,
      vin18,
      vin19,
      vin20,
      dangerousGoods,
      undgCode,
      undgContactName,
      undgContactNo,
      saveButton,
      updateButton
    }
  }

  function getVinList() {
    const result = []

    for (let i = 1; i <= 20; i++) {
      const fieldName = `vin${i}`

      // If value is valid (not null or empty)
      if (goodsDetails[fieldName]) {
        result.push({
          label: translatedTextsObject[fieldName],
          value: goodsDetails[fieldName]
        })
      }
    }

    return result
  }

  function handleAddGoodsDetails(data) {
    const goodsDetails = [...formContext.getValues('goodsDetails'), data].map(
      (goodsDetails) => ({
        ...goodsDetails,
        _id: ++lastIndex.current
      })
    )

    formContext.setValue('goodsDetails', goodsDetails, { shouldValidate: true })
    onClose()
  }

  function handleEditGoodsDetails(data) {
    const goodsDetails = [...formContext.getValues('goodsDetails')]
    const index = goodsDetails.findIndex((goodsDetails) =>
      data.id ? goodsDetails.id === data.id : goodsDetails._id === data._id
    )

    formContext.setValue(`goodsDetails.${index}`, data, {
      shouldValidate: true
    })
    onClose()
  }

  if (!open) {
    return null
  }

  let viewContent = goodsDetails && (
    <CngSection title='Classification &amp; weight'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} md={4} />
            <Grid item xs={12} md={8}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CngField label={translatedTextsObject.goodsDescription}>
                    {goodsDetails.goodsDescription}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CngField label={`${translatedTextsObject.noOfPackages} & ${translatedTextsObject.noOfPackagesUom}`}>
                    <Typography component='div' variant='inherit'>
                      {goodsDetails.noOfPackages}
                      <TinyChip label={goodsDetails.noOfPackagesUom} />
                    </Typography>
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CngField label={`${translatedTextsObject.grossWeight} & ${translatedTextsObject.grossWeightUom}`}>
                    <Typography component='div' variant='inherit'>
                      {goodsDetails.grossWeight}
                      <TinyChip label={goodsDetails.grossWeightUom} />
                    </Typography>
                  </CngField>
                </Grid>
                {shipmentType !== null && (
                  <>
                    {(shipmentType === 'SEC 321' ||
                      shipmentType === 'IN-BOND' ||
                      shipmentType === 'ASTRAY') && (
                      <Grid item xs={12} sm={6}>
                        <CngField label={translatedTextsObject.monetaryAmount}>
                          {goodsDetails.monetaryAmount}
                        </CngField>
                      </Grid>
                    )}
                    {shipmentType === 'SEC 321' && (
                      <Grid item xs={12} sm={6}>
                        <CngField label={translatedTextsObject.countryOfOrigin}>
                          {goodsDetails.countryOfOrigin}
                        </CngField>
                      </Grid>
                    )}
                    {shipmentType === 'BRASS' && (
                      <Grid item xs={12}>
                        <CollapsibleSection
                          title={translatedTextsObject.c4CodeTitle}
                          footerText='Additional Information'
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <CngField label={translatedTextsObject.c4Code1}>
                                {goodsDetails.c4Code1}
                              </CngField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <CngField label={translatedTextsObject.c4Code2}>
                                {goodsDetails.c4Code2}
                              </CngField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <CngField label={translatedTextsObject.c4Code3}>
                                {goodsDetails.c4Code3}
                              </CngField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <CngField label={translatedTextsObject.c4Code4}>
                                {goodsDetails.c4Code4}
                              </CngField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <CngField label={translatedTextsObject.c4Code5}>
                                {goodsDetails.c4Code5}
                              </CngField>
                            </Grid>
                          </Grid>
                        </CollapsibleSection>
                      </Grid>
                    )}
                  </>
                )}
                {entryType != null &&
                  (entryType == '62' || entryType == '63') && (
                    <Grid item xs={12}>
                      <CollapsibleSection
                        title={translatedTextsObject.harmonizedCodeTitle}
                        footerText='Additional Information'
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <CngField label={translatedTextsObject.harmonizedCode1}>
                              {goodsDetails.harmonizedCode1}
                            </CngField>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <CngField label={translatedTextsObject.harmonizedCode2}>
                              {goodsDetails.harmonizedCode2}
                            </CngField>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <CngField label={translatedTextsObject.harmonizedCode3}>
                              {goodsDetails.harmonizedCode3}
                            </CngField>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <CngField label={translatedTextsObject.harmonizedCode4}>
                              {goodsDetails.harmonizedCode4}
                            </CngField>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <CngField label={translatedTextsObject.harmonizedCode5}>
                              {goodsDetails.harmonizedCode5}
                            </CngField>
                          </Grid>
                        </Grid>
                      </CollapsibleSection>
                    </Grid>
                  )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <CngSubSection title={translatedTextsObject.dangerousGoods}>
            {goodsDetails.dangerousGoods.length > 0 ? (
              <Grid container spacing={2}>
                {goodsDetails.dangerousGoods.map((dangerousGoods, index) => (
                  <React.Fragment key={Math.random()}>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                          <CngField label={translatedTextsObject.undgCode}>
                            {dangerousGoods.undgCode}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <CngField label={translatedTextsObject.undgContactName}>
                            {dangerousGoods.undgContactName}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <CngField label={translatedTextsObject.undgContactNo}>
                            {dangerousGoods.undgContactNo}
                          </CngField>
                        </Grid>
                      </Grid>
                    </Grid>
                    {goodsDetails.dangerousGoods.length !== index + 1 && (
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
              </Grid>
            ) : (
              <Typography variant='body2' color='textSecondary'>
                No records to display
              </Typography>
            )}
          </CngSubSection>
        </Grid>
        <Grid item xs={12}>
          <CngSubSection title={translatedTextsObject.vinTitle}>
            {getVinList().length > 0 ? (
              <Grid container>
                <Grid item xs={12} md={2} />
                <Grid item xs={12} md={10}>
                  <Grid container spacing={2}>
                    {getVinList().map((vin, index) => (
                      <Grid item key={index} xs={12} md={6}>
                        <CngField label={vin.label}>{vin.value}</CngField>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Typography variant='body2' color='textSecondary'>
                No records to display
              </Typography>
            )}
          </CngSubSection>
        </Grid>
        <Grid item xs={12}>
          <CngSection title={translatedTextsObject.marksNoTitle}>
            {[
              goodsDetails.marksNo1,
              goodsDetails.marksNo2,
              goodsDetails.marksNo3,
              goodsDetails.marksNo4,
              goodsDetails.marksNo5
            ].filter((markNo) => markNo).length > 0 ? (
              <Grid container>
                <Grid item xs={12} md={4} />
                <Grid item xs={12} md={8}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <CngField label={translatedTextsObject.marksNo1}>
                        {goodsDetails.marksNo1}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CngField label={translatedTextsObject.marksNo2}>
                        {goodsDetails.marksNo2}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CngField label={translatedTextsObject.marksNo3}>
                        {goodsDetails.marksNo3}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CngField label={translatedTextsObject.marksNo4}>
                        {goodsDetails.marksNo4}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CngField label={translatedTextsObject.marksNo5}>
                        {goodsDetails.marksNo5}
                      </CngField>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Typography variant='body2' color='textSecondary'>
                No records to display
              </Typography>
            )}
          </CngSection>
        </Grid>
      </Grid>
    </CngSection>
  )

  return (
    <CngDialog
      dialogContent={
        goodsDetails && isView ? (
          viewContent
        ) : (
          <CngForm
            fieldLevel='form'
            formikProps={{
              initialValues: goodsDetails || initialValues,
              makeValidationSchema: makeValidationSchema,
              onSubmit: (data) => {
                if (goodsDetails) {
                  handleEditGoodsDetails(data)
                } else {
                  handleAddGoodsDetails(data)
                }
              }
            }}
            formState={FormState.COMPLETED}
            innerForm={true}
            renderBodySection={(labelMap, shouldHideMap) => (
              <GoodsDetailsFormProperties.Fields
                showNotification={showNotification}
                shouldHideMap={shouldHideMap}
                shipmentTypeState={shipmentType}
                entryTypeState={entryType}
                masterShipment={masterShipment}
              />
            )}
            renderButtonSection={() => (
              <Box display='flex' justifyContent='flex-end'>
                <CngPrimaryButton
                  type='submit'
                  disabled={
                    formContext ? formContext.formState.isSubmitting : false
                  }
                >
                  {goodsDetails
                    ? translatedTextsObject.updateButton
                    : translatedTextsObject.saveButton}
                </CngPrimaryButton>
              </Box>
            )}
          />
        )
      }
      dialogTitle={translatedTextsObject.goodsDetails}
      dialogAction={
        isView ? <CngButton onClick={onClose}>OK</CngButton> : null
      }
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      fullWidth
      maxWidth='md'
      onClose={onClose}
      open={open}
      shouldShowCloseButton
    />
  )
}

export default GoodsDetailsDialog
