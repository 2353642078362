import React, { useEffect } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import AceHighwayShipmentKeys from 'src/constants/locale/key/AceHighwayShipment'
import { Grid } from '@material-ui/core'
import { components, useTranslation } from 'cng-web-lib'
import { useFormContext, useWatch } from 'react-hook-form'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'

const {
  form: {
    field: { CngTextField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  // _goodsDetailsId: 0,
  undgCode: "",
  undgContactName: "",
  undgContactNo: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap,
  index
}) {
  const { setValue } = useFormContext();
  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_SHIPMENT)
  const translatedTextsObject = makeTranslatedTextsObject()

  useWatch("fileForUserId");
  useWatch("fileForUserLoginId")
  useWatch("fileForUserPartyId");
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue("fileForUserId", fileForUserDetails.fileForUserId)
      setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
      setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)
    }
  }, []);


  function makeTranslatedTextsObject() {
    let dangerousGoods = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.DangerousGoods.TITLE
    )
    let undgCode = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.DangerousGoods.UNDG_CODE
    )
    let undgContactName = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.DangerousGoods.UNDG_CONTACT_NAME
    )
    let undgContactNo = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.DangerousGoods.UNDG_CONTACT_NO
    )

    return {
      dangerousGoods,
      undgCode,
      undgContactName,
      undgContactNo
    }
  }

	return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.undgCode}>
        <CngTextField
          required
          name={typeof index === 'number' ? `dangerousGoods.${index}.undgCode` : 'undgCode'}
          label={translatedTextsObject.undgCode}
          disabled={disabled}
          onBlur={(e) => {
            if (e.target.value !== undefined) {
              setValue(`dangerousGoods.${index}.undgCode`, e.target.value.trim(), { shouldValidate: true })
            }
          }}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.undgContactName}>
        <CngTextField
          required
          name={typeof index === 'number' ? `dangerousGoods.${index}.undgContactName` : 'undgContactName'}
          label={translatedTextsObject.undgContactName}
          disabled={disabled}
          onChange={(e) => {
            setValue(`dangerousGoods.${index}.undgContactName`, e.target.value.toUpperCase(), { shouldValidate: true })
          }}
          onBlur={(e) => {
            if (e.target.value !== undefined) {
              setValue(`dangerousGoods.${index}.undgContactName`, e.target.value.trim(), { shouldValidate: true })
            }
          }}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.undgContactNo}>
        <CngTextField
          required
          name={typeof index === 'number' ? `dangerousGoods.${index}.undgContactNo` : 'undgContactNo'}
          label={translatedTextsObject.undgContactNo}
          disabled={disabled}
          onChange={(e) => {
            setValue(`dangerousGoods.${index}.undgContactNo`, e.target.value.toUpperCase(), { shouldValidate: true })
          }}
          onBlur={(e) => {
            if (e.target.value !== undefined) {
              setValue(`dangerousGoods.${index}.undgContactNo`, e.target.value.trim(), { shouldValidate: true })
            }
          }}
          size='small'
        />
      </CngGridItem>
    </Grid>
	)
}

const DangerousGoodsFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default DangerousGoodsFormProperties
