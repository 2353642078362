import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'
import AceHighwayShipmentKeys from 'src/constants/locale/key/AceHighwayShipment'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  let maxLengthErrorMessage = translate(
    Namespace.ACE_HIGHWAY_SHIPMENT,
    AceHighwayShipmentKeys.Validations.MAX_LENGTH
  )

  let alphaNumericErrorMessage = translate(
    Namespace.ACE_HIGHWAY_SHIPMENT,
    AceHighwayShipmentKeys.Validations.ALPHANUMERIC
  )
  let alphaNumericSpecialCharErrorMessage = translate(
    Namespace.ACE_HIGHWAY_SHIPMENT,
    AceHighwayShipmentKeys.Validations.ALPHANUMERIC_SPECIAL_CHAR
  )

  let numericErrorMessage = translate(
    Namespace.ACE_HIGHWAY_SHIPMENT,
    AceHighwayShipmentKeys.Validations.NUMERIC
  )

  let minLengthErrorMessage = translate(
    Namespace.ACE_HIGHWAY_SHIPMENT,
    AceHighwayShipmentKeys.Validations.MIN_LENGTH
  )
  
  let regexNumeric = "^[0-9]*$"
  let regexAlphaNumeric = "^[a-zA-Z0-9 ]*$"
  let regexAlphaNumericSpecChar= "^[a-zA-Z0-9 ,+\\-$#@%&()!'`^_:;.?\\[\\]\\{\\}\\/=\"\\|\\\\ ]*$"

  return Yup.object({
    goodsDescription: Yup.string().required(requiredMessage).matches(regexAlphaNumericSpecChar,alphaNumericSpecialCharErrorMessage).max(512,maxLengthErrorMessage + " 512"),
    noOfPackages: Yup.string().required(requiredMessage).matches(regexNumeric,numericErrorMessage).max(8,maxLengthErrorMessage + " 8"),
    noOfPackagesUom: Yup.string().required(requiredMessage),
    grossWeight: Yup.string().required(requiredMessage).matches(regexNumeric,numericErrorMessage).max(10,maxLengthErrorMessage + " 10"),
    grossWeightUom: Yup.string().required(requiredMessage),
    monetaryAmount: Yup.string().when('shipmentType', {
      is: (shipmentType) => (shipmentType == "SEC 321" || shipmentType == "IN-BOND" || shipmentType == "ASTRAY"),
      then:  Yup.string().required(requiredMessage).nullable().matches(regexNumeric,numericErrorMessage).max(8,maxLengthErrorMessage + " 8")
    }).nullable(),
    countryOfOrigin:  Yup.string().when('shipmentType', {
      is: (shipmentType) => (shipmentType == "SEC 321"),
      then:   Yup.string().required(requiredMessage).nullable(),
    }).nullable(),
    harmonizedCode1: Yup.string().nullable().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(10,maxLengthErrorMessage + " 10"),
    harmonizedCode2: Yup.string().nullable().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(10,maxLengthErrorMessage + " 10"),
    harmonizedCode3: Yup.string().nullable().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(10,maxLengthErrorMessage + " 10"),
    harmonizedCode4: Yup.string().nullable().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(10,maxLengthErrorMessage + " 10"),
    harmonizedCode5: Yup.string().nullable().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(10,maxLengthErrorMessage + " 10"),
    c4Code1: Yup.string().nullable().matches(regexAlphaNumeric,alphaNumericErrorMessage).min(14,minLengthErrorMessage + " 14").max(14,maxLengthErrorMessage + " 14"),
    c4Code2: Yup.string().nullable().matches(regexAlphaNumeric,alphaNumericErrorMessage).min(14,minLengthErrorMessage + " 14").max(14,maxLengthErrorMessage + " 14"),
    c4Code3: Yup.string().nullable().matches(regexAlphaNumeric,alphaNumericErrorMessage).min(14,minLengthErrorMessage + " 14").max(14,maxLengthErrorMessage + " 14"),
    c4Code4: Yup.string().nullable().matches(regexAlphaNumeric,alphaNumericErrorMessage).min(14,minLengthErrorMessage + " 14").max(14,maxLengthErrorMessage + " 14"),
    c4Code5: Yup.string().nullable().matches(regexAlphaNumeric,alphaNumericErrorMessage).min(14,minLengthErrorMessage + " 14").max(14,maxLengthErrorMessage + " 14"),
    marksNo1: Yup.string().nullable().matches(regexAlphaNumericSpecChar,alphaNumericSpecialCharErrorMessage).max(35,maxLengthErrorMessage + " 35"),
    marksNo2: Yup.string().nullable().matches(regexAlphaNumericSpecChar,alphaNumericSpecialCharErrorMessage).max(35,maxLengthErrorMessage + " 35"),
    marksNo3: Yup.string().nullable().matches(regexAlphaNumericSpecChar,alphaNumericSpecialCharErrorMessage).max(35,maxLengthErrorMessage + " 35"),
    marksNo4: Yup.string().nullable().matches(regexAlphaNumericSpecChar,alphaNumericSpecialCharErrorMessage).max(35,maxLengthErrorMessage + " 35"),
    marksNo5: Yup.string().nullable().matches(regexAlphaNumericSpecChar,alphaNumericSpecialCharErrorMessage).max(35,maxLengthErrorMessage + " 35"),
    vin1: Yup.string().nullable().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(30,maxLengthErrorMessage + " 30"),
    vin2: Yup.string().nullable().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(30,maxLengthErrorMessage + " 30"),
    vin3: Yup.string().nullable().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(30,maxLengthErrorMessage + " 30"),
    vin4: Yup.string().nullable().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(30,maxLengthErrorMessage + " 30"),
    vin5: Yup.string().nullable().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(30,maxLengthErrorMessage + " 30"),
    vin6: Yup.string().nullable().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(30,maxLengthErrorMessage + " 30"),
    vin7: Yup.string().nullable().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(30,maxLengthErrorMessage + " 30"),
    vin8: Yup.string().nullable().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(30,maxLengthErrorMessage + " 30"),
    vin9: Yup.string().nullable().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(30,maxLengthErrorMessage + " 30"),
    vin10: Yup.string().nullable().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(30,maxLengthErrorMessage + " 30"),
    vin11: Yup.string().nullable().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(30,maxLengthErrorMessage + " 30"),
    vin12: Yup.string().nullable().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(30,maxLengthErrorMessage + " 30"),
    vin13: Yup.string().nullable().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(30,maxLengthErrorMessage + " 30"),
    vin14: Yup.string().nullable().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(30,maxLengthErrorMessage + " 30"),
    vin15: Yup.string().nullable().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(30,maxLengthErrorMessage + " 30"),
    vin16: Yup.string().nullable().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(30,maxLengthErrorMessage + " 30"),
    vin17: Yup.string().nullable().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(30,maxLengthErrorMessage + " 30"),
    vin18: Yup.string().nullable().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(30,maxLengthErrorMessage + " 30"),
    vin19: Yup.string().nullable().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(30,maxLengthErrorMessage + " 30"),
    vin20: Yup.string().nullable().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(30,maxLengthErrorMessage + " 30"),
    dangerousGoods: Yup.array(Yup.object({
      undgCode: Yup.string().required(requiredMessage).matches(regexNumeric,numericErrorMessage).min(4,minLengthErrorMessage + " 4").max(4,maxLengthErrorMessage + " 4").nullable(),
      undgContactName: Yup.string().required(requiredMessage).matches(regexAlphaNumericSpecChar,alphaNumericSpecialCharErrorMessage).max(30,maxLengthErrorMessage + " 30").nullable(),
      undgContactNo: Yup.string().required(requiredMessage).matches(regexAlphaNumericSpecChar,alphaNumericSpecialCharErrorMessage).max(30,maxLengthErrorMessage + " 30").nullable(),
    })).nullable()
  })
}

export default makeValidationSchema
