import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants
} from 'cng-web-lib'
import AceHighwayShipmentKeys from 'src/constants/locale/key/AceHighwayShipment'

const {
  locale: {
    key: { CommonValidationMessageKeys },
  },
} = constants


function makeValidationSchema(translate) {

  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )
  let minLengthErrorMessage = translate(
    Namespace.ACE_HIGHWAY_SHIPMENT,
    AceHighwayShipmentKeys.Validations.MIN_LENGTH
  )
  let maxLengthErrorMessage = translate(
    Namespace.ACE_HIGHWAY_SHIPMENT,
    AceHighwayShipmentKeys.Validations.MAX_LENGTH
  )
  let alphaNumericErrorMessage = translate(
    Namespace.ACE_HIGHWAY_SHIPMENT,
    AceHighwayShipmentKeys.Validations.ALPHANUMERIC
  )
  let numericErrorMessage = translate(
    Namespace.ACE_HIGHWAY_SHIPMENT,
    AceHighwayShipmentKeys.Validations.NUMERIC
  )
  let alphaNumericSpecialCharErrorMessage = translate(
    Namespace.ACE_HIGHWAY_SHIPMENT,
    AceHighwayShipmentKeys.Validations.ALPHANUMERIC_SPECIAL_CHAR
  )
  let emailValid = translate(
    Namespace.ACE_HIGHWAY_SHIPMENT,
    AceHighwayShipmentKeys.Validations.EMAIL_VALID
  )


  let regexAlphaNumeric = "^[a-zA-Z0-9 ]*$"
  let regexAlphaNumericDash = "^[a-zA-Z0-9- ]*$"
  let regexNumeric = "^[0-9]*$"
  let regexAlphaNumericSpecChar = "^[a-zA-Z0-9 ,+\\-$#@%&()!'`^_:;.?\\[\\]\\{\\}\\/=\"\\|\\\\ ]*$"
  let regexEIN = "^\\d{2}\\-\\d{9}$"
  let regexSSN = "^\\d{3}\\-\\d{2}\\-\\d{4}$"
  let regexCAN = "^\\d{6}\\-\\d{5}$"

  return Yup.object({
    shipmentType: Yup.string().required(requiredMessage).nullable(),
    entryType: Yup.string().when('shipmentType', {
      is: (shipmentType) => (shipmentType === 'IN-BOND' || shipmentType === 'SEC 321'),
      then: Yup.string().required(requiredMessage).nullable(),
    }).nullable(),
    scn: Yup.string().required(requiredMessage).matches(regexAlphaNumeric, alphaNumericErrorMessage).min(5, minLengthErrorMessage + " 5").max(16, maxLengthErrorMessage + " 16"),
    acquittalNo: Yup.string().nullable().matches(regexAlphaNumeric, alphaNumericErrorMessage).max(10, maxLengthErrorMessage + " 10"),
    pointOfLoading: Yup.string().required(requiredMessage).nullable(),
    fdaIndicator: Yup.string().nullable(),
    serviceType: Yup.string().nullable(),
    inBond7512No: Yup.string().when('shipmentType', {
      is: (shipmentType) => (shipmentType === 'IN-BOND'),
      then: Yup.string().required(requiredMessage).matches(regexAlphaNumeric, alphaNumericErrorMessage).max(12, maxLengthErrorMessage + " 12"),
    }).nullable(),
    masterInBondNo: Yup.string().nullable().matches(regexAlphaNumeric, alphaNumericErrorMessage).max(12, maxLengthErrorMessage + " 12"),
    mexicanPedimento: Yup.string().nullable().matches(regexAlphaNumeric, alphaNumericErrorMessage).max(15, maxLengthErrorMessage + " 15"),
    foreignDestination: Yup.string().when('entryType', {
      is: (entryType) => (entryType === '62' || entryType === '63'),
      then: Yup.string().required(requiredMessage).nullable(),
    }).nullable(),
    inBondDestination: Yup.string().when('shipmentType', {
      is: (shipmentType) => (shipmentType === 'IN-BOND'),
      then: Yup.string().required(requiredMessage),
    }).nullable(),
    firmsCode: Yup.string().nullable().matches(regexAlphaNumeric, alphaNumericErrorMessage).max(4, maxLengthErrorMessage + " 4"),
    exitDate: Yup.lazy((value) => value === '' || value === null || value === undefined ?
      Yup.string().when('shipmentType', {
        is: (shipmentType) => (shipmentType === 'ASTRAY'),
        then: Yup.string().required(requiredMessage)
      }).when('entryType', {
        is: (entryType) => (entryType === '62' || entryType === '63'),
        then: Yup.string().required(requiredMessage)
      }).nullable() : Yup.date().validFormat().typeError(dateTypeErrorMessage)
    ),
    quantity: Yup.number().typeError(numericErrorMessage).nullable().when('splitIndicator', {
      is: (splitIndicator) => (splitIndicator),
      then: Yup.number().typeError(numericErrorMessage).min(1, minLengthErrorMessage + " 1").required(requiredMessage).nullable(),
    }),

    partyInfo: Yup.array(
      Yup.object({
        partyDropDown: Yup.string().nullable(),
        partyType: Yup.string().required(requiredMessage).nullable(),
        saveFlag: Yup.boolean().nullable(),
        partyIdentifier:
          Yup.string().when('saveFlag', {
            is: true,
            then: Yup.string().trim().required(requiredMessage).matches(regexAlphaNumericSpecChar, alphaNumericSpecialCharErrorMessage),
          }).nullable(),
        name1: Yup.string().matches(regexAlphaNumericSpecChar, alphaNumericSpecialCharErrorMessage).max(35, maxLengthErrorMessage + " 35").nullable()
        .when('partyType', {
          is: (partyType) => (partyType !== 'GC'),
          then: Yup.string().required(requiredMessage),
        }).nullable(),
        name2: Yup.string().nullable().matches(regexAlphaNumericSpecChar, alphaNumericSpecialCharErrorMessage).max(35, maxLengthErrorMessage + " 35"),
        address1: Yup.string().when('partyType', {
          is: (partyType) => (partyType === 'OS' || partyType === 'CN'),
          then: Yup.string().required(requiredMessage).nullable().matches(regexAlphaNumericSpecChar, alphaNumericSpecialCharErrorMessage).max(35, maxLengthErrorMessage + " 35"),
        }).nullable(),
        address2: Yup.string().nullable().matches(regexAlphaNumericSpecChar, alphaNumericSpecialCharErrorMessage).max(35, maxLengthErrorMessage + " 35"),
        address3: Yup.string().nullable().matches(regexAlphaNumericSpecChar, alphaNumericSpecialCharErrorMessage).max(35, maxLengthErrorMessage + " 35"),
        cityName:
          Yup.string().when('partyType', {
            is: (partyType) => (partyType === 'OS' || partyType === 'CN'),
            then: Yup.string().required(requiredMessage).nullable().matches(regexAlphaNumericSpecChar, alphaNumericSpecialCharErrorMessage).max(35, maxLengthErrorMessage + " 35"),
          }).nullable(),
        postalCode:
          Yup.string().when('partyType', {
            is: (partyType) => (partyType === 'OS' || partyType === 'CN'),
            then: Yup.string().required(requiredMessage).nullable().matches(regexAlphaNumericDash, alphaNumericErrorMessage).max(10, maxLengthErrorMessage + " 10"),
          }).nullable(),
        country:
          Yup.string().when('partyType', {
            is: (partyType) => (partyType === 'OS' || partyType === 'CN'),
            then: Yup.string().required(requiredMessage).nullable(),
          }).nullable(),
        state: Yup.string().nullable(),
        telNo: Yup.string().nullable().matches(regexAlphaNumericSpecChar, alphaNumericSpecialCharErrorMessage).min(10, minLengthErrorMessage + " 10").max(25, maxLengthErrorMessage + " 25"),
        phoneNo: Yup.string().nullable().matches(regexAlphaNumericSpecChar, alphaNumericSpecialCharErrorMessage).max(25, maxLengthErrorMessage + " 25"),
        faxNo: Yup.string().nullable().matches(regexAlphaNumericSpecChar, alphaNumericSpecialCharErrorMessage).max(25, maxLengthErrorMessage + " 25"),
        email: Yup.string().nullable().email(emailValid).max(50, maxLengthErrorMessage + " 50"),
        ctpatInd: Yup.string().nullable(),
        partyIdentifierCode:
          Yup.string().when('partyType', {
            is: (partyType) => (partyType === 'NI' || partyType === 'GC' || partyType === 'IM'),
            then: Yup.string().required(requiredMessage).nullable(),
          }).nullable(),
        partyIdentifierValue:
          Yup.string().when('partyType', {
            is: (partyType) => (partyType === 'NI' || partyType === 'GC' || partyType === 'IM'),
            then: Yup.string().required(requiredMessage).nullable().matches(regexAlphaNumericSpecChar, alphaNumericErrorMessage).max(33, maxLengthErrorMessage + " 33")
          })
            .when('partyType', {
              is: (partyType) => (partyType === 'CB'),
              then: Yup.string().required(requiredMessage).nullable().matches(regexAlphaNumericSpecChar, alphaNumericErrorMessage).min(3, minLengthErrorMessage + " 3").max(3, maxLengthErrorMessage + " 3")
            })
            .when('partyIdentifierCode', {
              is: (partyIdentifierCode) => (partyIdentifierCode === '167'),
              then: Yup.string().required(requiredMessage).nullable().matches(regexEIN, "Incorrect Party Identifier format: 12-123456789")
            })
            .when('partyIdentifierCode', {
              is: (partyIdentifierCode) => (partyIdentifierCode === '161'),
              then: Yup.string().required(requiredMessage).nullable().matches(regexSSN, "Incorrect Party Identifier format: 123-45-6789")
            })
            .when('partyIdentifierCode', {
              is: (partyIdentifierCode) => (partyIdentifierCode === '160'),
              then: Yup.string().required(requiredMessage).nullable().matches(regexCAN, "Incorrect Party Identifier format: 123456-12345")
            })
            .nullable(),
        portCode: Yup.string().when('partyType', {
          is: (partyType) => (partyType === 'CB'),
          then: Yup.string().required(requiredMessage).nullable().min(4, minLengthErrorMessage + " 4").max(4, maxLengthErrorMessage + " 4"),
        }).nullable(),
        routingCode: Yup.string().when('partyType', {
          is: (partyType) => (partyType === 'CB'),
          then: Yup.string().nullable(),
        }).nullable(),
        brokerDownloadRefNo: Yup.string().when('partyType', {
          is: (partyType) => (partyType === 'CB'),
          then: Yup.string().required(requiredMessage).nullable().matches(regexAlphaNumeric, alphaNumericErrorMessage).max(35, maxLengthErrorMessage + " 35"),
        }).nullable(),
        portOfLoading: Yup.string().when('partyType', {
          is: (partyType) => (partyType === 'CB'),
          then: Yup.string().required(requiredMessage).nullable(),
        }).nullable()
      })
    ),

    goodsDetails: Yup.array().nullable(),
    dangerousGoods: Yup.array().nullable(),
  })
}

export default makeValidationSchema
